import React, { useEffect, useState } from "react";
import { DashboardService } from "../../../services/dashboardService/dashboardService";
import VideoTrackerPercentage from "../../../utils/common-functions/VideoTrackerPercentage";
import { useSelector } from "react-redux";

const RecordedVideoSession = ({ video, id = null }) => {
    const userType = useSelector((state) => state?.auth?.userData?.user_type);
    const { videoRef, integerDuration, percentageWatched } = VideoTrackerPercentage();
    const [attendanceUpdated, setAttendanceUpdated] = useState(false);

    const updateAttendance = () => {
        if (userType !== 4 && id) {
            DashboardService.updatedKeynoteAttendance(id, percentageWatched.toFixed(2), integerDuration)
                .then((response) => {
                    DashboardService.getMilestone();
                })
                .catch((error) => {
                    console.error("API error:", error);
                });
        }
    };

    const handlePause = () => {
        updateAttendance();
        setAttendanceUpdated(true);
    };

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.addEventListener("pause", handlePause);
        }

        return () => {
            if (videoRef.current) {
                videoRef.current.removeEventListener("pause", handlePause);
            }
        };
    }, [videoRef, percentageWatched, id, attendanceUpdated]);

    return (
        <div>
            <video ref={videoRef} controls className="dashboard-video" crossOrigin="anonymous">
                <source src={video} type="video/mp4" />
            </video>
        </div>
    );
};

export default RecordedVideoSession;
