import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import { Modal } from "react-bootstrap";
import CatapultLogo from "../../../../assets/paxton-patterson.jpeg";
import ModalClose from "../../../../assets/modal_close.png";
import "react-quill/dist/quill.snow.css";
import "../../../projects/modal/styles/add-project.css";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import {welcomeVideoService} from "../../../../services/authService/welcomeVideoService";
import moment from "moment/moment";
import {useDispatch} from "react-redux";
import {setWatchedAt} from "../../../../redux/authSlice";
import RecordedVideoSession from "../../../dashboard/components/RecordedVideoSession";

const WelcomeVideoModal = () => {
    // Start states
    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [videoUrl, setVideoUrl] = useState(null);
    const [showModal, setShowModal] = useState(true);
    // End  states

    const handleClose = () => {
        setShowModal(false);
    };

    useEffect(() => {
        getVideoLink()
    }, []);

    const getVideoLink = async () => {
        try {
            const response = await welcomeVideoService.getWelcomeVideoUrl();
            setVideoUrl(response.data);
        } catch (error) {
            console.log('error during getting welcome video');
            setVideoUrl(null);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            setIsSubmitting(true)
            await welcomeVideoService.confirmWatching();
            const currentTime = moment().format();
            dispatch(setWatchedAt(currentTime));
            setShowModal(false);
        } catch (error) {
            console.log('something went wrong during confirming', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    if (!showModal) {
        return <></>;
    }

    return (
        <Modal onHide={handleClose} show="true" aria-labelledby="contained-modal-title-vcenter" centered dialogClassName="modal-90w" className="add-project-popup">
            <Modal.Header>
                <img src={CatapultLogo}></img>
                <div className="modal-close" onClick={() => handleClose()}>
                    <img src={ModalClose}></img>
                </div>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: "-webkit-fill-available", overflowY: "auto" }}>
                <form onSubmit={handleSubmit}>
                    <div className="box col-sm-12 col-lg-12 col-md-12">
                        <div className="question">
                            Have you watched it yet?
                        </div>
                        <Button variant="primary" type="submit" className="ml-30" disabled={isSubmitting}>
                            Confirm
                        </Button>
                    </div>
                    <div className="col-md-7 col-sm-12 col-lg-7 mt-4 pr-15">
                        <div className="question">
                            Please watch the following video
                        </div>
                    </div>
                </form>
            </Modal.Body>
            {videoUrl &&
                <RecordedVideoSession video={videoUrl}/>
            }
        </Modal>
    );
};

export default WelcomeVideoModal;
