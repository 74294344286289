/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Modal } from "react-bootstrap";
import CatapultLogo from "../../../assets/paxton-patterson.jpeg";
import ModalClose from "../../../assets/modal_close.png";
import "./add-post.css";
import { miniBookService } from "../../../services/miniBookService/miniBookService";
import Loader from "../../../components/common/Loader";
import { toast } from "react-toastify";

const AddPost = ({ onClose, questionId, parentId, isReply = false, isEdit = false, discussionId, getCommunity }) => {
    const handleClose = (data) => {
        onClose(data);
    };

    const [title, setTitle] = useState("");
    const [loading, setLoading] = useState(false);
    const [description, setDescription] = useState("");
    const [isError, setIsError] = useState(false);
    const [getGeneralData, setGetGeneralData] = useState(null);

    useEffect(() => {
        if (isEdit) {
            getGereralDiscussionsById();
        }
    }, [isEdit]);

    useEffect(() => {
        if (isEdit && getGeneralData) {
            setTitle(getGeneralData.title);
            setDescription(getGeneralData.answer);
        }
    }, [isEdit, getGeneralData]);

    const getGereralDiscussionsById = () => {
        miniBookService.getPlaybookDiscussionById(discussionId !== undefined ? discussionId : parentId).then((discussions) => {
            setGetGeneralData(discussions?.data?.data);
        });
    };

    const addPost = () => {
        if (isReply) {
            if (description == "") {
                setIsError(true);
                return false;
            } else {
                setIsError(false);
            }
            setLoading(true);
            miniBookService
                .addPost({
                    play_book_course_question_id: questionId,
                    parent_id: parentId,
                    answer: description,
                })
                .then((res) => {
                    setLoading(false);
                    handleClose(res);
                });
        } else {
            if (title == "" || description == "") {
                setIsError(true);
                return false;
            } else {
                setIsError(false);
            }
            setLoading(true);
            miniBookService
                .addPost({
                    play_book_course_question_id: questionId,
                    parent_id: parentId,
                    title: title,
                    answer: description,
                })
                .then((res) => {
                    setLoading(false);
                    handleClose(res);
                });
        }
    };

    const editPost = () => {
        if (isEdit) {
            if (title == "" || description == "") {
                setIsError(true);
                return false;
            } else {
                setIsError(false);
            }
            miniBookService
                .editPlaybookDiscussion({
                    community_discussion_id: discussionId !== undefined ? discussionId : parentId,
                    title: title,
                    answer: description,
                })
                .then((res) => {
                    setLoading(false);
                    handleClose(res);
                    toast.success("Post updated successfully.");
                    getCommunity();
                });
        }
    };

    const handleAddTitle = (e) => {
        setTitle(e.target.value);
    };

    const handleAddDescription = (e) => {
        setDescription(e.target.value);
    };

    const handlePostNow = () => {
        const trimmedTitle = title?.trim();
        const trimmedDescription = description.trim();

        if (isEdit && (!isReply ? trimmedTitle === "" || trimmedDescription === "" : trimmedDescription === "")) {
            setIsError(true);
            return false;
        } else if (!isEdit && (!isReply ? trimmedTitle === "" || trimmedDescription === "" : trimmedDescription === "")) {
            setIsError(true);
            return false;
        } else {
            setIsError(false);
        }

        if (isEdit) {
            editPost();
        } else {
            addPost();
        }
    };
    return (
        <Modal onHide={handleClose} show="true" size="lg" aria-labelledby="contained-modal-title-vcenter" className={loading ? "add-modal transparent" : "add-modal"} centered>
            {loading ? (
                <>
                    <Loader></Loader>
                </>
            ) : (
                <>
                    <Modal.Header>
                        <img src={CatapultLogo}></img>
                        <div className="modal-close" onClick={() => handleClose()}>
                            <img src={ModalClose}></img>
                        </div>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: "-webkit-fill-available", overflowY: "auto" }}>
                        <div className="row w-100">
                            <div className="box col-sm-12 col-lg-12 col-md-12">
                                <Button variant="primary" className="ml-30" onClick={handlePostNow} disabled={!isReply ? title?.trim() == "" || description.trim() == "" : description.trim() == ""}>
                                    {isEdit ? "Update Post" : "Post Now"}
                                </Button>
                            </div>
                            <div className="col-md-12 col-sm-12 col-lg-12 mt-4 pr-15">
                                <div>
                                    {!isReply && (
                                        <div className="box-2">
                                            <div className="question">
                                                Title <span className="question-asterisk">*</span>
                                            </div>
                                            <div className="mt-4 w-ful mb-2">
                                                <input
                                                    className="form-control-input"
                                                    placeholder="Write your post title here"
                                                    value={title}
                                                    onChange={(e) => {
                                                        handleAddTitle(e);
                                                    }}
                                                />
                                            </div>
                                            {isError && title == "" && <div className="error">Title is required</div>}
                                        </div>
                                    )}

                                    <div className="box-2 mt-4">
                                        <div className="question">
                                            Description <span className="question-asterisk">*</span>
                                        </div>
                                        <div className="mt-4 w-100">
                                            <textarea
                                                className="form-control-textarea"
                                                rows={3}
                                                cols={50}
                                                value={description}
                                                style={{ resize: "none" }}
                                                onChange={(e) => {
                                                    handleAddDescription(e);
                                                }}
                                            ></textarea>
                                        </div>
                                        {isError && description == "" && <div className="error">Description is required</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </>
            )}
        </Modal>
    );
};

export default AddPost;
