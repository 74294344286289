/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { IoNotificationsOutline } from "react-icons/io5";
import { RiMenu2Line } from "react-icons/ri";

import CatapaultLogo from "../../../assets/paxton-patterson-logo.png";
import Asidebar from "./asidebar";
import avatarUser from "../../../assets/avatarUser.png";
import { useSelector } from "react-redux";
import NotificationsDropdown from "./NotificationsDropdown";
import { notificationService } from "../../../services/notificationService/NotificationServices";

const Header = () => {
    const [showNotificationsDropdown, setShowNotificationsDropdown] = useState(false);
    const userProfileData = useSelector((state) => state?.auth?.userData);
    const [notificationCount, setNotificationCount] = useState(0);
    const notificationIconRef = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [allNotifications, setAllNotifications] = useState([]);

    const allNotificationsData = async () => {
        if (!showNotificationsDropdown) {
            setIsLoading(true);
            const res = await notificationService.getNotification();
            const filteredNotifications = res?.data?.data.filter((notification) => notification.is_open === 0);
            setAllNotifications(filteredNotifications);
            setNotificationCount(filteredNotifications?.length);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!showNotificationsDropdown) {
            allNotificationsData();

            const intervalId = setInterval(() => {
                allNotificationsData();
            }, 10000);

            return () => clearInterval(intervalId);
        }
    }, [showNotificationsDropdown]);

    const handleNotificationsClick = async () => {
        setShowNotificationsDropdown(!showNotificationsDropdown);
        if (notificationCount === 0) {
            allNotificationsData();
        } else {
            await notificationService.postNotification();
        }
        setNotificationCount(0);
    };

    return (
        <>
            {/* Header for desktop start */}
            <header className="header d-lg-flex d-none position-fixed header-section">
                <div>
                    <img crossOrigin="anonymous" className="px-5 header-logo" src={CatapaultLogo} alt="Catapault"  />
                </div>
                <div className="d-flex px-5">
                    <div className="d-flex gap-4 fs-4 px-3">
                        {/* <AiOutlineSearch className="header-icon" /> */}
                        {/* <HiOutlineMail className="header-icon" /> */}
                        <div className="dropdown">
                            <button
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                id="notificatioDropdown"
                                className="btn text-white fs-4 p-0  position-relative border border-0"
                                onClick={handleNotificationsClick}
                                // ref={notificationIconRef}
                            >
                                <IoNotificationsOutline className="header-icon" />
                                {notificationCount > 0 && <span className="position-absolute     noti-count top-0 start-100 translate-middle badge rounded-pill bg-danger">{notificationCount}</span>}
                            </button>
                            <NotificationsDropdown iconRef={notificationIconRef} isLoading={isLoading} allNotifications={allNotifications} />
                        </div>
                    </div>
                    <div className="d-flex  justify-content-center align-items-center">
                        <img crossOrigin="anonymous" src={userProfileData?.full_profile_image || avatarUser} alt="User" className="mx-2 header-profile-image" />
                        <span className="fs-6 fw-bold">
                            {userProfileData?.first_name}
                            &nbsp;
                            {userProfileData?.last_name}
                        </span>
                    </div>
                </div>
            </header>
            {/* Header for desktop end */}
            {/* Header for mobile start*/}
            <header className="navbar navbar-dark  mobile-header-bg fixed-top d-block  d-lg-none">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/dashboard">
                        <img crossOrigin="anonymous" className="header-logo" src={CatapaultLogo} alt="Catapault" />
                    </a>
                    <div className="d-flex align-items-center">
                        <div className="dropdown">
                            <button
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                id="notificatioDropdown"
                                className="btn text-white fs-4 p-0  position-relative border border-0"
                                onClick={handleNotificationsClick}
                                // ref={notificationIconRef}
                            >
                                <IoNotificationsOutline className="header-icon" />
                                {notificationCount > 0 && <span className="position-absolute noti-count top-0 start-100 translate-middle badge rounded-pill bg-danger">{notificationCount}</span>}
                            </button>

                            <NotificationsDropdown iconRef={notificationIconRef} isLoading={isLoading} allNotifications={allNotifications} />
                        </div>
                        <button
                            className="navbar-toggler-custom border-none"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasNavbar"
                            aria-controls="offcanvasNavbar"
                            aria-label="Toggle navigation"
                        >
                            <RiMenu2Line className="text-white" />
                        </button>
                        <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                            <div className="offcanvas-header">
                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div className="offcanvas-body">
                                <div className="left-sidebar left-sidebar-mobile">
                                    <Asidebar />
                                </div>
                                <form className="d-flex mt-3" role="search">
                                    <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                                    <button className="btn btn-outline-success" type="submit">
                                        Search
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {/* Header for mobile end */}
        </>
    );
};

export default Header;
