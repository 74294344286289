import apiClient from "../../utils/apiClient";


const GET_DISCUSSION_CATEGORIES = "/discussion-categories";

const getDiscussionCategories = () => {
    const url = `${GET_DISCUSSION_CATEGORIES}`;

    return apiClient.get(url);
};

export const discussionCategoriesService = {
    getDiscussionCategories
};