import React, { useEffect, useState, useMemo } from "react";
import { CustomToolbar } from "../CustomToolbar";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "../Style/Schedular.css";
import { schedularService } from "../../../services/schedularCalendarService/SchedularCalendarService";
import convertUtcToSelectedTimeZone from "../../../utils/common-functions/ConvertUtcToTimezone";
import Swal from "sweetalert2";
import { ZOOM_SESSION } from "../../../lib/sessionTypes";
import { useNavigate } from "react-router-dom";
import TimezoneSelect from "./TimeZoneSelector";
import { MEETING_TYPES, ROUTES } from "../../../constants";
import { RiCheckboxBlankFill } from "react-icons/ri";
import {liveEventTypes} from "../../../utils/dictionaries/liveEventTypes";

const localizer = momentLocalizer(moment);
const defaultTZ = "PST8PDT";

const StudentScheduleCalendar = ({ formats, handleViewChange, currentDate, currentView, dayPropGetter }) => {
    // start states
    const [studentAvailableData, setStudenAvailableData] = useState([]);
    const navigate = useNavigate();
    const [timezone, setTimezone] = useState(defaultTZ);
    const [flag, setFlag] = useState(false);
    //end states

    // call api for get all availability of Speaker
    const getAllSpeakerAvailabilityData = () => {
        schedularService.getCalendarData().then((response) => {
            setStudenAvailableData(response?.data?.calendar);
            setFlag(response?.data?.isMeetingScheduled);
        });
    };

    // useEffect of call all api function
    useEffect(() => {
        getAllSpeakerAvailabilityData();
    }, []);

    // function for available schedules devided to 1 hour schedule
    function generateHourlySlots(start, end) {
        const slots = [];
        let current = moment(start);

        while (current.isBefore(end)) {
            const nextHour = moment(current).add(1, "hour");
            if (nextHour.isAfter(end)) {
                break;
            }
            slots.push({
                start: convertUtcToSelectedTimeZone(current.toDate()),
                end: convertUtcToSelectedTimeZone(nextHour.toDate()),
            });
            current = nextHour;
        }
        return slots;
    }
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const handleSession = (item) => {
        if (item.type === "meeting") {
            return navigate("/" + ROUTES.meetingPage.replace(":type", MEETING_TYPES.successCoachMeeting).replace(":id", item?.id));
        } else if (item.type === "previous_keynotes") {
            return navigate("/" + ROUTES.meetingPage.replace(":type", MEETING_TYPES.session).replace(":id", item?.keyNote?.id));
        } else if (item.type === "completed_keynotes") {
            return navigate("/" + ROUTES.meetingPage.replace(":type", MEETING_TYPES.session).replace(":id", item?.keyNote?.id));
        } else if (item.type === "special_event") {
            return navigate("/" + ROUTES.meetingPage.replace(":type", MEETING_TYPES.specialEvent).replace(":id", item?.id));
        }

        navigate("/dashboard", {
            state: {
                src: item?.meetingLink,
                sessionType: ZOOM_SESSION,
                sessionStartTime: item?.startTime,
            },
        });
        scrollToTop();
    };

    // === Event 1 === show events for success coach meeting schedule of login user
    const scheduleMeetingEvents =
        (Array.isArray(studentAvailableData?.Student?.poc_success_coach_meeting_details) &&
            studentAvailableData?.Student?.poc_success_coach_meeting_details
                ?.map((item) => {
                    const eventSlots = generateHourlySlots(convertUtcToSelectedTimeZone(`${item.start_date}`), convertUtcToSelectedTimeZone(`${item.end_date}`));

                    return eventSlots.map((slot) => ({
                        id: `${item.id}`,
                        title: `${item?.success_coach?.first_name} ${item?.success_coach?.last_name}`,
                        start: slot.start,
                        end: slot.end,
                        coachName: `${item?.success_coach_availability?.success_coach?.first_name} ${item?.success_coach_availability?.success_coach?.last_name}`,
                        date: `${item.date}`,
                        meetingLink: item?.meeting_details?.link,
                        type: "meeting",
                        masterClass: `${item?.master_class?.name}`,
                        handleSession: handleSession,
                        startTime: item?.start_time,
                        endTime: item?.end_time,
                    }));
                })
                .flat()) ||
        [];

    // === Event 2 === Show Previous Session of speaker
    const showPreviousSession = () => {
        const session = studentAvailableData?.Student?.sessions_details;
        const previousKeyNotes = session?.institution_key_notes;

        if (flag) {
            const previousSessionEvents = Array.isArray(previousKeyNotes)
                ? previousKeyNotes.map((item) => {
                      const startDate = convertUtcToSelectedTimeZone(`${item.start_date}`);
                      const endDate = convertUtcToSelectedTimeZone(`${item.end_date}`);

                      return {
                          id: item?.id,
                          start: startDate,
                          end: endDate,
                          title: item?.key_note?.name,
                          masterClass: item?.key_note?.master_class?.name,
                          type: "previous_keynotes",
                          keyNote: item?.key_note,
                          handleSession: handleSession,
                          meetingDetails: item?.key_note_online_booking?.details,
                          meetingDetailsId: item?.key_note_online_booking_details_id,
                          startTime: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
                          endTime: moment(endDate).format("HH:mm:ss"),
                      };
                  })
                : [];

            return previousSessionEvents;
        } else {
            return [];
        }
    };

    const previousSessionEvents = showPreviousSession();

    // === Event 3 === Show Completed session of poc
    const showCompletedSession = () => {
        const session = studentAvailableData?.Student?.sessions_details;
        const completedKeyNotes = session?.completed_key_notes;

        if (flag) {
            const completedSessionEvents = Array.isArray(completedKeyNotes)
                ? completedKeyNotes.map((item) => {
                      const startDate = convertUtcToSelectedTimeZone(`${item?.start_date}`);
                      const endDate = convertUtcToSelectedTimeZone(`${item?.end_date}`);

                      return {
                          id: item?.id,
                          start: startDate,
                          end: endDate,
                          title: item?.key_note?.name,
                          masterClass: item?.key_note?.master_class?.name,
                          type: "completed_keynotes",
                          keyNote: item?.key_note,
                          handleSession: handleSession,
                          meetingDetails: item?.key_note_online_booking?.details,
                          meetingDetailsId: item?.key_note_online_booking_details_id,
                          startTime: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
                          endTime: moment(endDate).format("HH:mm:ss"),
                      };
                  })
                : [];

            return completedSessionEvents;
        } else {
            return [];
        }
    };

    const completedSessionEvents = showCompletedSession();

    // === Event 8 === Show Live Events
    const showSpecialEvents = () => {
        const event = studentAvailableData?.Student?.special_events;

        if (flag) {
            const specialEvents = Array.isArray(event)
                ? event.map((item) => {
                      const startDate = convertUtcToSelectedTimeZone(`${item.start_date}`);
                      const endDate = convertUtcToSelectedTimeZone(`${item.end_date}`);

                      return {
                          id: item?.id,
                          start: startDate,
                          end: endDate,
                          title: item?.title,
                          type_label: item?.type_label,
                          type: "special_event",
                          handleSession: handleSession,
                          meetingDetails: item?.meeting_details?.details,
                          meetingDetailsId: item?.meeting_details_id,
                          startTime: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
                          endTime: moment(endDate).format("HH:mm:ss"),
                      };
                  })
                : [];
            return specialEvents;
        } else {
            return [];
        }
    };

    // combined all events
    const combinedEvents = [...scheduleMeetingEvents, ...previousSessionEvents, ...completedSessionEvents, ...showSpecialEvents()];

    // function for open sweet alert popup
    const handleEventClick = (event) => {
        const { start, end, type, masterClass, title } = event;

        const startTime = moment(start).format("hh:mm A");
        const endTime = moment(end).format("hh:mm A");
        const isMeetingEnd = moment().tz(timezone).isAfter(moment(end));

        if (type === "previous_keynotes") {
            Swal.fire({
                title: "Session Details",
                html: `
        <p><strong>Keynote Name:</strong> ${title}</p>
        <p><strong>Masterclass Name:</strong> ${masterClass}</p>
        <p><strong>Date:</strong> ${moment(start).format("LL")}</p>
        <p><strong>Time:</strong> ${startTime} - ${endTime}</p>`,
                icon: "info",
                showCancelButton: true,
                cancelButtonText: "Close",
                confirmButtonText: "Join Now",
                showConfirmButton: !isMeetingEnd,
            }).then((result) => {
                if (result.isConfirmed) {
                    handleSession(event);
                }
            });
        } else if (type === "completed_keynotes") {
            Swal.fire({
                title: "Session Details",
                html: `
        <p><strong>Keynote Name:</strong> ${title}</p>
        <p><strong>Masterclass Name:</strong> ${masterClass}</p>
        <p><strong>Date:</strong> ${moment(start).format("LL")}</p>
        <p><strong>Time:</strong> ${startTime} - ${endTime}</p>`,
                icon: "info",
                showCancelButton: true,
                cancelButtonText: "Close",
                confirmButtonText: "Join Now",
                showConfirmButton: !isMeetingEnd,
            }).then((result) => {
                if (result.isConfirmed) {
                    handleSession(event);
                }
            });
        } else if (type === "meeting") {
            Swal.fire({
                title: "Team Meeting Details",
                html: `
          <p><strong>Coach Name:</strong> ${title}</p>
          <p><strong>Masterclass:</strong> ${masterClass}</p>
          <p><strong>Date:</strong> ${moment(start).format("LL")}</p>
          <p><strong>Time:</strong> ${startTime} - ${endTime}</p>
        `,
                icon: "info",
                showCancelButton: true,
                confirmButtonText: "Join Now",
                showConfirmButton: !isMeetingEnd,
                cancelButtonText: "Close",
            }).then((result) => {
                if (result.isConfirmed) {
                    handleSession(event);
                }
            });
        } else if (type === "special_event") {
            Swal.fire({
                title: "Live Event Details",
                html: `
        <p><strong>Live Event Name:</strong> ${title}</p>
        <p><strong>Date:</strong> ${moment(start).format("LL")}</p>
        <p><strong>Time:</strong> ${startTime} - ${endTime}</p>`,
                icon: "info",
                showCancelButton: true,
                showConfirmButton: !isMeetingEnd,
                confirmButtonText: "Join Now",
                cancelButtonText: "Close",
            }).then((result) => {
                if (result.isConfirmed) {
                    handleSession(event);
                }
            });
        }
    };

    // Function to determine the background color of events
    const eventStyleGetter = (event) => {
        if (event.type === "meeting") {
            return {
                style: {
                    backgroundColor: "#e46a76",
                    color: "black",
                    marginTop: "25px",
                },
            };
        } else if (event.type === "previous_keynotes") {
            return {
                style: {
                    backgroundColor: "#03a9f3",
                    color: "#fff !important",
                },
            };
        } else if (event.type === "completed_keynotes") {
            return {
                style: {
                    backgroundColor: "#4CCABA",
                    color: "black",
                },
            };
        } else if (event.type === "special_event") {
            return {
                style: {
                    backgroundColor: liveEventTypes[event.type_label].color,
                    marginTop: "25px",
                },
            };
        }

        return {};
    };

    const { scrollToTime } = useMemo(() => {
        moment.tz.setDefault(timezone);
        return {};
    }, [timezone]);

    return (
        <>
            <h3 className="d-lg-block d-none ">Calendar</h3>
            <div className="card-layout schedular-section mt-4">
                <div className="calendar-container">
                    <div className="row m-0 py-3 px-4">
                        <div className="col-4">
                            <div className="d-flex gap-1 justify-content-start align-items-center">
                                <RiCheckboxBlankFill color="#4CCABA" fontSize={12} />
                                <span style={{ fontSize: "12px" }}>Completed Keynotes</span>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="d-flex gap-1 justify-content-start align-items-center">
                                <RiCheckboxBlankFill color="#03a9f3" fontSize={12} />

                                <span style={{ fontSize: "12px" }}> Upcoming Keynotes</span>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="d-flex gap-1 justify-content-start align-items-center">
                                <RiCheckboxBlankFill color="#e46a76" fontSize={12} />
                                <span style={{ fontSize: "12px" }}> Success Coach & POC Meeting</span>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="d-flex gap-1 justify-content-start align-items-center">
                                <RiCheckboxBlankFill color="rgba(0, 0, 0, 0)" fontSize={12}/>
                                <span style={{fontSize: "12px"}}>Live Event:</span>
                            </div>
                            <div className="d-flex gap-1 justify-content-start align-items-center">
                                <RiCheckboxBlankFill color={liveEventTypes['Conferences'].color} fontSize={12}/>
                                <span style={{fontSize: "12px"}}>Conferences</span>
                            </div>
                            <div className="d-flex gap-1 justify-content-start align-items-center">
                                <RiCheckboxBlankFill color={liveEventTypes['Best Practice Showcases'].color}
                                                     fontSize={12}/>
                                <span style={{fontSize: "12px"}}>Best Practice Showcases</span>
                            </div>
                            <div className="d-flex gap-1 justify-content-start align-items-center">
                                <RiCheckboxBlankFill color={liveEventTypes['Other'].color}
                                                     fontSize={12}/>
                                <span style={{fontSize: "12px"}}>Other</span>
                            </div>
                        </div>
                    </div>
                    <div className="rbc-toolbar justify-content-start gap-3" style={{padding: "20px"}}>
                        <span className="fs-6 fw-bold">Select Time Zone :</span>
                        <TimezoneSelect defaultTZ={defaultTZ} setTimezone={setTimezone} timezone={timezone} scrollToTime={scrollToTime} />
                    </div>
                    <Calendar
                        components={{
                            toolbar: (props) => <CustomToolbar {...props} onViewChange={handleViewChange} currentView={currentView} />,
                        }}
                        localizer={localizer}
                        formats={formats}
                        view={currentView}
                        events={combinedEvents}
                        date={currentDate?.toDate()}
                        style={{ height: 1260, color: "#fff", backgroundColor: "#374368" }}
                        startAccessor="start"
                        endAccessor="end"
                        dayPropGetter={dayPropGetter}
                        onSelectEvent={handleEventClick}
                        eventPropGetter={eventStyleGetter}
                    />
                </div>
            </div>
        </>
    );
};

export default StudentScheduleCalendar;
