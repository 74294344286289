import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ReactQuill from "react-quill";
import "./styles/add-comments.css";
import { ProjectsService } from "../../../services/projectsService/ProjectsService";
import { toast } from "react-toastify";
import CatapultLogo from "../../../assets/paxton-patterson.jpeg";
import ModalClose from "../../../assets/modal_close.png";

const AddCommentsReply = ({ handleClosePopup, selectedProjectId, allProjectsIdData, getAllCommetsIdData, commentId }) => {
    const [comment, setComment] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const handleClose = () => {
        handleClosePopup();
    };

    const handleCommentChange = (newComment) => {
        setIsButtonDisabled(false);
        setComment(newComment);
    };
    const addCommentData = async () => {
        setIsButtonDisabled(true);

        if (comment.replace(/\s/g, "") === "<p></p>") {
            toast.error("Invalid Input, Please avoid leading spaces at the beginning of the field");
        } else {
            const data = {
                project_id: selectedProjectId,
                parent_id: commentId,
                comment: comment,
            };
            await ProjectsService.addComments(data);
            toast.success("Your reply has been added successfully");
            setComment("");
            getAllCommetsIdData();
            allProjectsIdData();
            handleClosePopup();
        }
    };
    return (
        <>
            <Modal show={true} onHide={handleClose} className="comment-reply-popup modal-lg">
                <Modal.Header>
                    <img src={CatapultLogo}></img>
                    <div className="modal-close" onClick={() => handleClose()}>
                        <img src={ModalClose}></img>
                    </div>
                </Modal.Header>
                <Modal.Footer className="my-3 mx-3">
                    <Button variant="primary" onClick={addCommentData} disabled={isButtonDisabled}>
                        Save Reply
                    </Button>
                </Modal.Footer>

                <Modal.Body className="mx-3 my-3">
                    <ReactQuill theme="snow" value={comment} onChange={handleCommentChange} placeholder="Write your comment here" />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddCommentsReply;
