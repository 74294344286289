import React, {useState} from "react";
import {toast} from "react-toastify";
import wordIcon from "../../../assets/projects/word_file.png";
import pptIcon from "../../../assets/projects/ppt_file.png";
import pdfIcon from "../../../assets/projects/pdf_file.png";
import {Button, Modal} from "react-bootstrap";
import ModalClose from "../../../assets/modal_close.png";
import CloudUpload from "../../../assets/Cloud_Upload.png";
import {SharedResourcesService} from "../../../services/sharedResourcesService/sharedResourcesServices";
import ReactQuill from "react-quill";

const AddSharedResource = ({ handleClose, getAllResourcesData }) => {
    const [selectedFileName, setSelectedFileName] = useState(null);
    const [fileUpload, setFileUpload] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const allowedFileTypes = ["application/pdf", "image/jpg", "image/jpeg", "image/png", "application/msword", ".document", ".ms-powerpoint", ".presentation"];
    const [resourceFile, setResourceFile] = useState(null);
    const [resourceName, setResourceName] = useState(null);
    const [resourceDescription, setResourceDescription] = useState(null);

    const handleClosePopup = () => {
        handleClose();
    };

    const handleUploadedFile = () => {
        setSelectedFileName(null);
        setResourceFile(null);
    };

    const handleFileInputChange = (e) => {
        const selectedFile = e.target.files[0];
        handleFile(selectedFile);
    };

    const handleFileDrop = (e) => {
        e.preventDefault();
        const selectedFile = e.dataTransfer.files[0];
        handleFile(selectedFile);
    };

    const handleFile = (selectedFile) => {
        if (selectedFile) {
            const file = URL.createObjectURL(selectedFile);
            setFileUpload(file);
            setSelectedFileName(selectedFile.name);
            setResourceFile(selectedFile);
        }
    };

    const handleAbc = async () => {
        setIsSubmitting(true);
        try {
            const formData = new FormData();
            formData.append("resource_file", resourceFile);
            formData.append("name", resourceName);
            formData.append("description", resourceDescription);
            await SharedResourcesService.addResource(formData);
            handleClosePopup();
            getAllResourcesData();
            toast.success("Resource added successfully");
        } catch (error) {
            toast.error(error?.response?.data?.message);
        }
    };

    const getFileIcon = (fileName) => {
        const fileExtension = fileName?.split(".").pop();
        if ([".doc", ".docx"].includes("." + fileExtension)) {
            return <img crossOrigin="anonymous" src={wordIcon} className="uploaded-file" width={300} alt="Word Icon" />;
        } else if ([".ppt", ".pptx"].includes("." + fileExtension)) {
            return <img crossOrigin="anonymous" src={pptIcon} className="uploaded-file" width={300} alt="PowerPoint Icon" />;
        } else if ([".pdf"].includes("." + fileExtension)) {
            return <img crossOrigin="anonymous" src={pdfIcon} className="uploaded-file" width={300} alt="Pdf Icon" />;
        } else if ([".jpg", ".jpeg", ".png"].includes("." + fileExtension)) {
            return <img crossOrigin="anonymous" src={fileUpload} className="uploaded-file" width={300} alt="Upload Icon" />;
        }
    };

    return (
        <>
            <Modal onHide={handleClose} centered className="resources-file-uploaded-popup" show="true" size="lg" aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header className="modal-header m-0">
                    <div className="question">Upload Resource</div>
                    <div className="modal-close" onClick={() => handleClose()}>
                        <img src={ModalClose} alt="Close" />
                    </div>
                </Modal.Header>
                <form className={`form ${isSubmitting ? "form-not-clickable" : ""}   `}>
                    <Modal.Body
                        style={{ maxHeight: "450px", overflowY: "scroll" }}
                        className={`modal-body `}
                        onDragOver={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        onDragEnter={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        onDragLeave={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        onDrop={handleFileDrop}
                    >
                        <div className="dropzone">
                            <div className="row mt-3 px-3">
                                <div className="box col-sm-12 col-lg-12 col-md-12 flex-column gap-3">
                                    <div className="question">
                                        Resource Name<span className="question-asterisk">*</span> :
                                    </div>
                                    <input
                                        type="text"
                                        name="name"
                                        placeholder="Write your resource title here"
                                        autoComplete="off"
                                        onChange={(e) => setResourceName(e.target.value)}
                                        value={resourceName}
                                        maxLength={50}
                                    />
                                </div>
                            </div>
                            <div className="box-2 mt-4">
                                <div className="question">
                                    Add Resource Description <span className="question-asterisk">*</span>
                                </div>
                                <div className="mt-4 w-ful mb-2">
                                    <ReactQuill
                                        theme="snow"
                                        value={resourceDescription}
                                        onChange={(value) => setResourceDescription(value)}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3 px-3">
                                <div className="box col-sm-12 col-lg-12 col-md-12 flex-column gap-1">
                                    <div className="d-flex flex-column gap-3 w-100">
                                        <h5 className="text-black m-0">
                                            Resource File<span className="question-asterisk">*</span> :
                                        </h5>
                                        <div className=" w-full box-3 ">
                                            <div className="selected-file-box">
                                                {selectedFileName ? (
                                                    <>
                                                        <div
                                                            className="d-flex justify-content-between align-items-center">
                                                            <h6 className="m-0">{selectedFileName}</h6>
                                                            <div className="close-btn"
                                                                 onClick={() => handleUploadedFile()}>
                                                                <img src={ModalClose} alt="Close"/>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="selected-file-name mt-3">{getFileIcon(selectedFileName)}</div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <label htmlFor="fileInput" className="resources-file-uploaded">
                                                            <img crossOrigin="anonymous" src={CloudUpload}
                                                                 alt="Upload Icon"/>
                                                            <div className="text-1 mt-3 text-center">Select or drop a
                                                                file here
                                                            </div>
                                                            <div className="text-2 text-center">Files Sizes: Max 10MB
                                                            </div>
                                                            <div className="text-2 text-center">Files Extensions: .pdf,
                                                                .jpg, .jpeg, .png, .doc, .docx, .ppt, .pptx
                                                            </div>
                                                        </label>
                                                    </>
                                                )}
                                            </div>
                                            <input
                                                type="file"
                                                id="fileInput"
                                                name="resource_file"
                                                accept=".pdf, .jpg, .jpeg, .png, .doc, .docx, .ppt, .pptx"
                                                className="offscreen"
                                                required
                                                onClick={(e) => {
                                                    e.target.value = null;
                                                }}
                                                onChange={(e) => {
                                                    handleFileInputChange(e);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="primary"
                            type="submit"
                            className="btn btn-primary add-btn"
                            onClick={handleAbc}
                            disabled={resourceFile === null || resourceName === null || isSubmitting}
                        >
                            Add Resource
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
};

export default AddSharedResource;