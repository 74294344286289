/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import "../../projects/projects.css";
import "../sharedResources.css";
import Loader from "../../../components/common/Loader";
import notFound from "../../../assets/not-found/not_found.png";
import defaultImage from "../../../assets/avatarUser.png";
import Pagination from "@mui/material/Pagination";
import calculateTimeDifference from "../../../utils/common-functions/DisplayTimeFormat";
import { useNavigate } from "react-router-dom";
import {SharedResourcesService} from "../../../services/sharedResourcesService/sharedResourcesServices";
import {IoDocumentTextOutline} from "react-icons/io5";
import {BsCloudArrowDown} from "react-icons/bs";
import {BsTrash} from "react-icons/bs";
import {toast} from "react-toastify";
import AddPlusIcon from "../../../assets/add_plus_icon 2.svg";
import AddSharedResource from "../modal/add-shared-resource";
import {useSelector} from "react-redux";
import Swal from "sweetalert2";
import {communityService} from "../../../services/communityService/communityService";
import {AiFillHeart, AiOutlineHeart} from "react-icons/ai";
import {FaRegCommentDots} from "react-icons/fa";
import {FiEye} from "react-icons/fi";

const AllSharedResources = () => {
    const [key, setKey] = useState("allSharedResources");
    const [resourcesData, setResourcesData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const userId = useSelector((state) => state?.auth?.userData?.id);
    const limit = 12;
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const navigate = useNavigate();

    // call api for get all projects data
    const allResourcesData = () => {
        setIsLoading(true);
        SharedResourcesService.getAllResources(limit, page).then((response) => {
            setResourcesData(response?.data?.data);
            setIsLoading(false);
            setTotal(Math.ceil(response?.data?.total_records / limit));
        });
    };

    // useEffect of call all api function
    useEffect(() => {
        allResourcesData();
    }, [page]);

    const handleChange = (event, value) => {
        setPage(value);
    };

    const togglePopup = () => {
        setShowAddModal(!showAddModal);
    };

    const handleClose = () => {
        setShowAddModal(false);
    };

    const handleDownload = async (resource) => {
        try {
            const response = await fetch(resource.full_resource_path);
            const blob = await response.blob();
            const filename = (resource?.name || "resource_file").replace(/[^\w\s]/g, "_").replace(/\s+/g, "_");
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = filename;
            toast.success(`"${filename}" downloaded successfully`);
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    };

    const handleDeleteConfirmation = (event, id) => {
        event.stopPropagation();

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                handleDelete(id);
            }
        });
    };

    const handleDelete = (id) => {
        SharedResourcesService
            .deleteResource(id)
            .then((res) => {
                if (res) {
                    Swal.fire("Deleted!", "Your resource has been deleted.", "success");
                    allResourcesData();
                }
            })
            .catch((error) => {
                Swal.fire("Error!", "Failed to delete the resource.", "error");
            });
    };

    return (
        <>
            <div className="d-flex justify-content-between flex-column">
                <div className="d-flex justify-content-between align-items-center">
                    <h3 className="d-lg-block m-0 d-none">Shared Resources</h3>
                    <div className="d-flex justify-end">
                        <div
                            className="add-new-project"
                            onClick={togglePopup}
                        >
                            <img crossOrigin="anonymous" src={AddPlusIcon} style={{marginRight: "10px"}}></img>
                            <span style={{position: "relative", top: "3px"}}>Add New Resource</span>
                        </div>
                    </div>
                </div>
                <span className="mt-3">This is where you can see the Shared Resources of all other users.</span>
            </div>
            <div className="outer-card-layout mt-2 mt-lg-4 mt-md-4 all-projects-layout">
                {isLoading ? (
                    <Loader/>
                ) : (
                    <>
                        {key == "allSharedResources" && (
                            <>
                                {resourcesData?.length > 0 ? (
                                    <div className="row">
                                        {resourcesData?.map((data) => {
                                            const timeAgo = calculateTimeDifference(data?.created_at);

                                            return (
                                                <div
                                                    className="col-md-4 col-lg-6 col-xl-4 col-sm-6 mt-3 mt-lg-4 mt-md-4 mobile-view"
                                                    key={data?.id}
                                                >
                                                    <div className="margin-lr shared-resource-card cursor-pointer" onClick={() => navigate('/community/shared-resources/' + data?.id)}>
                                                        <>
                                                            {/*<div className="all-project-name mt-2">{data?.name}</div>*/}
                                                            <div className="all-project-detail d-block">
                                                                <div className="d-flex w-100 resource-card py-4 gap-0">
                                                                    <IoDocumentTextOutline className="recource-icon  "/>
                                                                    <div className="resources-name">{data?.name}</div>
                                                                </div>
                                                                <div
                                                                    className="d-flex justify-space-between align-center p-2">
                                                                    <div>
                                                                        <div className="d-flex gap-2">
                                                                            {data?.user?.full_profile_image !== null ? (
                                                                                <img crossOrigin="anonymous"
                                                                                     className="user-profile-image"
                                                                                     src={data?.user?.full_profile_image}></img>
                                                                            ) : (
                                                                                <img crossOrigin="anonymous"
                                                                                     className="user-profile-image"
                                                                                     src={defaultImage}></img>
                                                                            )}
                                                                            <div className="project-author-name">
                                                                                {data?.user?.first_name}&nbsp;{data?.user?.last_name}
                                                                            </div>
                                                                        </div>
                                                                        <div className="all-project-time">Posted
                                                                            - {timeAgo}.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex align-center p-2">
                                                                    <div>
                                                                        {data?.hasUserLiked === 1 ?
                                                                            <AiFillHeart fill="red"
                                                                                         fontSize={20}/> :
                                                                            <AiOutlineHeart fontSize={20}/>}
                                                                        <span
                                                                            className="image-text-span fs-6">{data?.likes}</span>
                                                                    </div>
                                                                    <div className="mx-2">
                                                                        <FaRegCommentDots fontSize={20}/>
                                                                        <span
                                                                            className="image-text-span fs-6">{data?.comments}</span>
                                                                    </div>
                                                                    {userId === data.user.id &&
                                                                        <BsTrash className="cursor-pointer" fill="#aa0000" fontSize={20}
                                                                                 onClick={(event) => handleDeleteConfirmation(event, data.id)}/>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <div
                                        className="w-100 mt-3 rounded-4 text-center d-flex flex-column align-items-center justify-content-center"
                                        style={{height: "500px"}}>
                                        <img crossOrigin="anonymous" src={notFound} alt="data not found" width={300}/>
                                        <h3>No Resources Added</h3>
                                        <p className="fs-5">No Shared Resources available at the moment.</p>
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}
                {key == "allSharedResources" && total > 0 && (
                    <div className="pagination-div my-2">
                        <Pagination count={total} page={page} onChange={handleChange}/>
                    </div>
                )}
            </div>
            {showAddModal && <AddSharedResource handleClose={handleClose} getAllResourcesData={allResourcesData}/>}
        </>
    );
};

export default AllSharedResources;
