import apiClient from "../../utils/apiClient";

const GET_COMMUNITY = "/get-community";
const GET_GENERAL_DISCUSSION = "/get-general-community";
const ADD_GENERAL_POST = "/add-general-post";
const ADD_DISCUSSION = "add-discussion";
const GET_ALL_KEYNOTES = "/get-all-keynotes";
const GET_GENERAL_DISCUSSIONS_BY_ID = "/get-general-discussion";
const EDIT_GENERAL_DISCUSSIONS = "/edit-general-discussion";
const DELETE_GENERAL_DISCUSSIONS = "/delete-general-discussion";

const getCommunity = (page, limit, selectedKeyNote) => {
    let url = `${GET_COMMUNITY}?limit=${limit}&page=${page}&keynoteId=${selectedKeyNote}`;
    return apiClient.get(url);
};

const getGeneralDiscussion = (page, limit, categoryId) => {
    const requestParams = new URLSearchParams();

    requestParams.append('limit', limit);
    requestParams.append('page', page);

    if (categoryId !== null && categoryId !== undefined) {
        requestParams.append('category_id', categoryId);
    }

    const queryString = requestParams.toString();

    let url = `${GET_GENERAL_DISCUSSION}${queryString ? '?' + queryString : ''}`;
    return apiClient.get(url);
};

const getAllKeyNoteList = () => {
    const url = `${GET_ALL_KEYNOTES}`;
    return apiClient.get(url);
};

const addGeneralPost = (data) => {
    const url = `${ADD_GENERAL_POST}`;
    return apiClient.post(url, data);
};

const addDiscussion = (data) => {
    const url = `${ADD_DISCUSSION}`;
    return apiClient.post(url, data);
};

const getGeneralDiscussionById = (id) => {
    const url = `${GET_GENERAL_DISCUSSIONS_BY_ID}/${id}`;
    return apiClient.get(url);
};

const editgeneralDiscussion = (data) => {
    const url = `${EDIT_GENERAL_DISCUSSIONS}`;
    return apiClient.post(url, data);
};

const deleteGeneralDiscussion = (id) => {
    const data = {
        general_community_discussion_id: id,
    };
    const url = `${DELETE_GENERAL_DISCUSSIONS}`;
    return apiClient.post(url, data);
};
export const communityService = {
    getCommunity,
    getGeneralDiscussion,
    addGeneralPost,
    addDiscussion,
    getAllKeyNoteList,
    getGeneralDiscussionById,
    editgeneralDiscussion,
    deleteGeneralDiscussion,
    //   addPost,
    //   getDiscussion
};
