import apiClient from "../../utils/apiClient";

const confirmWatching = () => {
    return apiClient.post("/watch-welcome-video");
};

const getWelcomeVideoUrl = () => {
    return apiClient.get('/welcome-video-url');
};

export const welcomeVideoService = {
    confirmWatching,
    getWelcomeVideoUrl
};