import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import {useFormik} from "formik";
import {toast} from "react-toastify";
import {Modal} from "react-bootstrap";
import CatapultLogo from "../../../assets/paxton-patterson.jpeg";
import ModalClose from "../../../assets/modal_close.png";
import Button from "react-bootstrap/Button";
import ReactQuill from "react-quill";
import {SharedResourcesService} from "../../../services/sharedResourcesService/sharedResourcesServices";

const EditResource = ({ onClose, selectedResourceId, resourceData, getResourceDetails }) => {
    // Start states
    const [isLoading, setIsLoading] = useState(false);
    const [isDisable, setIsDisable] = useState(false);
    const [quillContent, setQuillContent] = useState(resourceData?.description);
    const [hasChanges, setHasChanges] = useState(false);
    // End  states

    const validationSchema = Yup.object().shape({
        resourceName: Yup.string()
            .test("noLeadingSpaces", "Invalid Input, Please avoid leading spaces at the beginning of the field", (value) => {
                return !value || value.trimStart() === value;
            }),
        resourceDescription: Yup.string(),
    });

    const handleClose = () => {
        onClose();
    };

    const formik = useFormik({
        initialValues: {
            resourceName: resourceData?.name,
            resourceDescription: quillContent,
        },
        validationSchema,
        onSubmit: async (values) => {
            setIsDisable(false);
            setIsLoading(true);

            const data = {
                name: values.resourceName,
                description: quillContent,
                _method: "PUT",
            };

            await SharedResourcesService.updateResourceData(selectedResourceId, data)
                .then((res) => {
                    getResourceDetails();
                    toast.success("Your resource has been updated successfully");
                    handleClose();
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.message);
                });

            formik.resetForm();
            onClose();
        },
    });

    const handleDescriptionChange = (value) => {
        if (quillContent !== value) {
            setQuillContent(value);
            setIsDisable(true);
        } else {
            setIsDisable(false);
        }
    };

    useEffect(() => {
        setIsDisable(false);
    }, []);

    return (
        <Modal show="true" aria-labelledby="contained-modal-title-vcenter" centered dialogClassName="modal-90w" className="add-project-popup ">
            <Modal.Header>
                <img src={CatapultLogo}></img>
                <div className="modal-close" onClick={() => handleClose()}>
                    <img src={ModalClose}></img>
                </div>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: "-webkit-fill-available", overflowY: "auto" }}>
                <form onSubmit={formik.handleSubmit}>
                    <div className="row w-100">
                        <div className="box col-sm-12 col-lg-12 col-md-12">
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={!isDisable}
                                className="ml-30"
                            >
                                Update
                            </Button>
                        </div>
                        <div className="col-md-7 col-sm-12 col-lg-7 mt-4 pr-15">
                            <div>
                                <div className="box-2">
                                    <div className="question">
                                        Resource Name <span className="question-asterisk">*</span>
                                    </div>
                                    <div className="mt-4 w-ful mb-2">
                                        <input
                                            type="text"
                                            name="resourceName"
                                            placeholder="Write your project title here"
                                            onChange={(event) => {
                                                formik.handleChange(event);
                                                setIsDisable(true);
                                            }}
                                            onBlur={(event) => {
                                                formik.handleBlur(event);
                                            }}
                                            value={formik.values.resourceName}
                                            autoComplete="off"
                                            maxLength={50}
                                        />

                                        {formik.touched.resourceName && formik.errors.resourceName && <div className="error">{formik.errors.resourceName}</div>}
                                    </div>
                                </div>

                                <div className="box-2 mt-4">
                                    <div className="question">
                                        Add Resource Description <span className="question-asterisk">*</span>
                                    </div>
                                    <div className="mt-4 w-ful mb-2">
                                        <ReactQuill
                                            theme="snow"
                                            value={quillContent}
                                            onChange={handleDescriptionChange}
                                            onBlur={(event) => {
                                                setIsDisable(true);
                                                formik.handleBlur("resourceDescription", true);
                                            }}
                                        />

                                        {formik.touched.resourceDescription && formik.errors.resourceDescription && <div className="error">{formik.errors.resourceDescription}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default EditResource;