/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/rules-of-hooks */
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { profileSettingService } from "../../services/profileSettingService/profileSettingService";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Select from "react-select";

const ProfileSettingsForm = ({ getUserData, getUserAllData, setIsLoading }) => {
    // states start
    const loginUserData = useSelector((state) => state?.auth?.userData);
    const [isSubmitting, setIsSubmitting] = useState(false);
    // states end

    const getUserTypeString = (userType) => {
        switch (userType) {
            case 3:
                return "POC";
            case 4:
                return "Speaker";
            case 5:
                return "Educator";
            default:
                return "";
        }
    };

    // Create a function to handle bio changes
    const handleBioChange = (e) => {
        formik.handleChange(e);
        setIsSubmitting(false);
    };

    useEffect(() => {
        setIsSubmitting(true);
        formik.setValues({
            firstName: getUserData?.first_name || "",
            lastName: getUserData?.last_name || "",
            email: getUserData?.email || "",
            profession: getUserData?.user_type || "",
            bio: getUserData?.bio || "",
            city: getUserData?.city || "",
            district: getUserData?.district || "",
            state: getUserData?.state || "",
            favorite_module: getUserData?.favorite_module || "",
            professional_expertise_areas: getUserData?.professional_expertise_areas || "",
            years_experience: getUserData?.years_experience || null,
            lab_type: {
                value: getUserData?.lab_type,
                label: getUserData?.lab_type,
            } || {
                value: "",
                label: "",
            },
        });
        setIsSubmitting(true);
    }, [getUserData]);

    // Define initial form values
    const initialValues = {
        firstName: "",
        lastName: "",
        bio: "",
        city: "",
        district: "",
        state: "",
        favorite_module: "",
        professional_expertise_areas: "",
        lab_type: "",
        years_experience: null,
    };

    // Define form validation schema using Yup
    const validationSchema = Yup.object({
        firstName: Yup.string()
            .test("noLeadingSpaces", "Invalid Input, Please avoid leading spaces at the beginning of the field", (value) => {
                return !value || value.trimStart() === value;
            })
            .max(20, "First Name cannot exceed 20 characters")
            .required("First Name is required"),
        lastName: Yup.string()
            .test("noLeadingSpaces", "Invalid Input, Please avoid leading spaces at the beginning of the field", (value) => {
                return !value || value.trimStart() === value;
            })
            .max(20, "Last Name cannot exceed 20 characters")
            .required("Last Name is required"),
        bio: Yup.string()
            .test("noLeadingSpaces", "Invalid Input, Please avoid leading spaces at the beginning of the field", (value) => {
                return !value || value.trimStart() === value;
            })
            .max(500, "Bio cannot exceed 500 characters"),
        city: Yup.string()
            .test("noLeadingSpaces", "Invalid Input, Please avoid leading spaces at the beginning of the field", (value) => {
                return !value || value.trimStart() === value;
            })
            .max(100, "City cannot exceed 100 characters"),
        district: Yup.string()
            .test("noLeadingSpaces", "Invalid Input, Please avoid leading spaces at the beginning of the field", (value) => {
                return !value || value.trimStart() === value;
            })
            .max(100, "District cannot exceed 100 characters"),
        state: Yup.string()
            .test("noLeadingSpaces", "Invalid Input, Please avoid leading spaces at the beginning of the field", (value) => {
                return !value || value.trimStart() === value;
            })
            .max(100, "State cannot exceed 100 characters"),
        favorite_module: Yup.string()
            .test("noLeadingSpaces", "Invalid Input, Please avoid leading spaces at the beginning of the field", (value) => {
                return !value || value.trimStart() === value;
            })
            .max(250, "Favorite Module cannot exceed 250 characters"),
        professional_expertise_areas: Yup.string()
            .test("noLeadingSpaces", "Invalid Input, Please avoid leading spaces at the beginning of the field", (value) => {
                return !value || value.trimStart() === value;
            })
            .max(250, "Areas of Professional Expertise cannot exceed 250 characters"),
        years_experience: Yup.number()
            .nullable()
            .max(100, "Years Experience in PP Lab cannot be more then 100 years"),
    });

    // Initialize Formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            setIsLoading(true);
            try {
                setIsSubmitting(true);
                const formData = new FormData();

                formData.append("first_name", values.firstName);
                formData.append("last_name", values.lastName);

                formData.append("bio", values.bio);
                formData.append("city", values.city);
                formData.append("district", values.district);
                formData.append("state", values.state);
                formData.append("favorite_module", values.favorite_module);
                formData.append("professional_expertise_areas", values.professional_expertise_areas);
                formData.append("years_experience", values.years_experience);
                formData.append("_method", "PUT");

                if (values.lab_type.value === null) {
                    formData.append("lab_type", "");
                }
                if (values.lab_type.value) {
                    formData.append("lab_type", values.lab_type.value);
                }

                if (values.years_experience === null) {
                    formData.append("years_experience", "");
                }
                if (values.years_experience) {
                    formData.append("years_experience", values.years_experience);
                }

                await profileSettingService.addUserProfileData(loginUserData?.id, formData);
                toast.success("Your profile has been updated successfully");
                getUserAllData();
                setIsLoading(false);
            } catch (error) {
                console.error("API error:", error);
                setIsLoading(false);
            }
        },
    });

    return (
        <>
            {/*  JSX code for profile settings form start */}
            <form onSubmit={formik.handleSubmit} className="profile-setting-form form p-0 px-lg-3 px-md-3 ">
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 mb-4">
                        <div className="input-gr">
                            <label htmlFor="firstName" className="form-label mb-2">
                                First Name
                            </label>
                            <input
                                type="text"
                                id="firstName"
                                name="firstName"
                                className="form-control"
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setIsSubmitting(false);
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.firstName}
                                autoComplete="off"
                                maxLength={20}
                            />
                            {formik.touched.firstName && formik.errors.firstName ?
                                <div className="text-danger">{formik.errors.firstName}</div> : null}
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 mb-4">
                        <div className="input-gl">
                            <label htmlFor="lastName" className="form-label mb-2">
                                Last Name
                            </label>
                            <input
                                type="text"
                                id="lastName"
                                name="lastName"
                                className="form-control"
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setIsSubmitting(false);
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.lastName}
                                autoComplete="off"
                                maxLength={20}
                            />
                            {formik.touched.lastName && formik.errors.lastName ?
                                <div className="text-danger">{formik.errors.lastName}</div> : null}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="mb-4">
                        <label htmlFor="email" className="form-label mb-2">
                            Email
                        </label>
                        <input type="email" id="email" name="email" className="form-control" onBlur={formik.handleBlur}
                               value={formik.values.email} disabled/>
                        {formik.touched.email && formik.errors.email ?
                            <div className="text-danger">{formik.errors.email}</div> : null}
                    </div>
                </div>
                <div className="row">
                    <div className="mb-4">
                        <label htmlFor="profession" className="form-label mb-2">
                            Profession
                        </label>
                        <input
                            type="text"
                            id="profession"
                            name="profession"
                            className="form-control"
                            onBlur={formik.handleBlur}
                            value={getUserData?.user_type ? getUserTypeString(getUserData.user_type) : ""}
                            disabled
                        />
                        {formik.touched.profession && formik.errors.profession ?
                            <div className="text-danger">{formik.errors.profession}</div> : null}
                    </div>
                </div>
                <div className="row">
                    <div className="mb-4">
                        <label htmlFor="bio" className="form-label mb-2">
                            Bio
                        </label>
                        <textarea
                            type="text"
                            id="bio"
                            name="bio"
                            rows={5}
                            cols={40}
                            className="form-control form-textarea"
                            onChange={handleBioChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.bio}
                            style={{resize: "none"}}
                            autoComplete="off"
                            maxLength={500}
                        />
                        {formik.touched.bio && formik.errors.bio ?
                            <div className="text-danger">{formik.errors.bio}</div> : null}
                    </div>
                </div>
                <div className="row">
                    <div className="mb-4">
                        <label className="form-label mb-2">
                            Skills
                        </label>
                        <div className="skills">
                            {getUserData?.tags.map((tag) => (
                                <span className="skills__badge">
                                    {tag.name}
                                </span>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 mb-4">
                        <div className="input-gr">
                            <label htmlFor="district" className="form-label mb-3">
                                Lab Type
                            </label>
                            <Select
                                name="lab_type"
                                options={[
                                    {
                                        value: 'CCR',
                                        label: 'CCR'
                                    },
                                    {
                                        value: 'CCL',
                                        label: 'CCL'
                                    },
                                    {
                                        value: 'HSC',
                                        label: 'HSC'
                                    },
                                ]}
                                value={formik.values.lab_type}
                                onChange={(labType) => {
                                    formik.setFieldValue('lab_type', labType);
                                    setIsSubmitting(false);
                                }}
                                placeholder="Please select..."
                            />
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 mb-4">
                        <div className="input-gl">
                            <label htmlFor="city" className="form-label mb-2">
                                City
                            </label>
                            <input
                                type="text"
                                id="city"
                                name="city"
                                className="form-control"
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setIsSubmitting(false);
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.city}
                                autoComplete="off"
                                maxLength={100}
                            />
                            {formik.touched.city && formik.errors.city ?
                                <div className="text-danger">{formik.errors.city}</div> : null}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 mb-4">
                        <div className="input-gr">
                            <label htmlFor="district" className="form-label mb-2">
                                District
                            </label>
                            <input
                                type="text"
                                id="district"
                                name="district"
                                className="form-control"
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setIsSubmitting(false);
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.district}
                                autoComplete="off"
                                maxLength={100}
                            />
                            {formik.touched.district && formik.errors.district ?
                                <div className="text-danger">{formik.errors.district}</div> : null}
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 mb-4">
                        <div className="input-gl">
                            <label htmlFor="state" className="form-label mb-2">
                                State
                            </label>
                            <input
                                type="text"
                                id="state"
                                name="state"
                                className="form-control"
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setIsSubmitting(false);
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.state}
                                autoComplete="off"
                                maxLength={100}
                            />
                            {formik.touched.state && formik.errors.state ?
                                <div className="text-danger">{formik.errors.state}</div> : null}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 mb-4">
                        <div className="input-gr">
                            <label htmlFor="years_experience" className="form-label mb-2">
                                Years Experience in PP Lab
                            </label>
                            <input
                                type="number"
                                id="years_experience"
                                name="years_experience"
                                className="form-control"
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setIsSubmitting(false);
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.years_experience}
                                autoComplete="off"
                            />
                            {formik.touched.years_experience && formik.errors.years_experience ?
                                <div className="text-danger">{formik.errors.years_experience}</div> : null}
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 mb-4">
                        <div className="input-gl">
                            <label htmlFor="favorite_module" className="form-label mb-2">
                                Favorite Module
                            </label>
                            <input
                                type="text"
                                id="favorite_module"
                                name="favorite_module"
                                className="form-control"
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setIsSubmitting(false);
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.favorite_module}
                                autoComplete="off"
                                maxLength={250}
                            />
                            {formik.touched.favorite_module && formik.errors.favorite_module ?
                                <div className="text-danger">{formik.errors.favorite_module}</div> : null}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 mb-4">
                        <div className="input-gr">
                            <label htmlFor="professional_expertise_areas" className="form-label mb-2">
                                Areas of Professional Expertise
                            </label>
                            <input
                                type="text"
                                id="professional_expertise_areas"
                                name="professional_expertise_areas"
                                className="form-control"
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setIsSubmitting(false);
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.professional_expertise_areas}
                                autoComplete="off"
                                maxLength={250}
                            />
                            {formik.touched.professional_expertise_areas && formik.errors.professional_expertise_areas ?
                                <div className="text-danger">{formik.errors.professional_expertise_areas}</div> : null}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 ">
                        <button type="submit" className="btn btn-primary" disabled={!formik.dirty || isSubmitting}>
                            Save profile
                        </button>
                    </div>
                </div>
            </form>
            {/*  JSX code for profile setting form end */}
        </>
    );
};

export default ProfileSettingsForm;
