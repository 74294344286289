import React, { useState } from "react";
import Download from "../../assets/download.png";
import Certificate from "../../assets/certificate-web.png";
import "./certificates.css";
import { useSelector } from "react-redux";
import { certificateServices } from "../../services/CertificateDowloadService/CertificateDowloadService";
import { toast } from "react-toastify";
import Pro from "../../assets/progressbarIcons/pro.svg";
import Noob from "../../assets/progressbarIcons/noob.svg";
import Rookie from "../../assets/progressbarIcons/rookie.png";
import ProgressBar from "@ramonak/react-progress-bar";
import certificate1 from "../../assets/certificate/career_readiness.png";
import Certificate2 from "../../assets/certificate/instructional_excellence.png";
import Certificate3 from "../../assets/certificate/student_recruitment_marketing.png";
import Certificate4 from "../../assets/certificate/work_based_learning.png";

const Certificates = () => {
    const progressBarCount = useSelector((state) => state?.auth?.progressBar);
    const [isDownloading, setIsDownloading] = useState(false);
    const isDownloadDisabled = progressBarCount <= 60 || isDownloading;
    const masterClass = localStorage.getItem("masterClass");

    const handleDownload = async () => {
        try {
            setIsDownloading(true);
            const res = await certificateServices.getCertificate();

            const downloadUrl = res?.data?.data;

            const downloadLink = document.createElement("a");
            downloadLink.href = downloadUrl;
            downloadLink.download = "certificate.pdf";
            downloadLink.target = "_blank";

            toast.success("Your certificate has been downloaded successfully");

            downloadLink.style.display = "none";

            document.body.appendChild(downloadLink);

            downloadLink.click();

            document.body.removeChild(downloadLink);

            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error("Error downloading certificate:", error);
            toast.error("Failed to download certificate");
        } finally {
            setIsDownloading(false);
        }
    };

    return (
        <>
            <h3 className="d-lg-block d-none title">My Certificate</h3>
            <div className="outer-card-layout mt-4">
                <div className="mt-4">Team Professional Development: {masterClass === "null" ? "" : masterClass}</div>
                <hr className="mt-4 hr-d"></hr>
                <div className="mt-4">
                    Paxton Patterson offers a unique team professional development opportunity. Typically, Paxton Patterson is designed to be a catalyst for galvanizing support for your CTE programs across your
                    entire institution; it facilitates a true tipping point by providing the best Industry Thought Leaders to every employee across your entire institution.
                </div>
                <hr className="mt-4 hr-d"></hr>

                <div className="mt-8">
                    <div className="d-flex  gap-20">
                        {progressBarCount <= 30 ? (
                            <>
                                <img crossOrigin="anonymous" src={Noob} alt="" />

                                <div style={{ flexGrow: 1 }}>
                                    <ProgressBar completed={progressBarCount} bgColor="rgba(255, 210, 0, 1)" baseBgColor="#fff" className="w-100" />
                                    <div className="mt-2 date-text wrap">
                                        Your Paxton Patterson Masterclass certificate will be available to download once your progress reaches 60% or above. Keep learning because your involvement is what will
                                        build your impact.
                                    </div>
                                </div>
                            </>
                        ) : progressBarCount <= 60 ? (
                            <>
                                <img crossOrigin="anonymous" src={Rookie} alt="" />
                                <div style={{ flexGrow: 1 }}>
                                    <ProgressBar completed={progressBarCount} bgColor="rgba(255, 210, 0, 1)" baseBgColor="#fff" className="w-100" />
                                    <div className="mt-2 date-text wrap">
                                        Your Paxton Patterson Masterclass certificate will be available to download once your progress reaches 60% or above. Keep learning because your involvement is what will
                                        build your impact.
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <img crossOrigin="anonymous" src={Pro} alt="" />
                                <div style={{ flexGrow: 1 }}>
                                    <div className="congrat-text wrap">
                                        Congratulations on getting <b> your certificate!</b>
                                    </div>
                                    <div className="mt-2 date-text wrap">You have successfully completed the course.</div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="mt-4 certificate-img">
                    {masterClass === "Career Readiness" ? (
                        <img crossOrigin="anonymous" src={certificate1} className="c-img" alt=""></img>
                    ) : masterClass === "Work Based Learning" ? (
                        <img crossOrigin="anonymous" src={Certificate4} className="c-img" alt=""></img>
                    ) : masterClass === "Instructional Excellence" ? (
                        <img crossOrigin="anonymous" src={Certificate2} className="c-img" alt=""></img>
                    ) : masterClass === "Student Recruitment and Marketing" ? (
                        <img crossOrigin="anonymous" src={Certificate3} className="c-img" alt=""></img>
                    ) : (
                        ""
                    )}
                </div>
                <div
                    className="mt-4 mb-4 d-flex fd"
                    style={{
                        gap: "20px",
                        justifyContent: "center",
                    }}
                >
                    {/* <div className="btn-br width-210">
                        <img crossOrigin="anonymous" src={Share}></img>
                        Share Certificate
                    </div> */}
                    <button className="btn btn-primary" disabled={isDownloadDisabled} onClick={handleDownload}>
                        {isDownloading ? (
                            <span className="fs-5 fw-bolder">Downloading...</span>
                        ) : (
                            <>
                                <img crossOrigin="anonymous" src={Download} style={{ marginRight: "10px", width: "25px", height: "25px" }} />
                                <span className="fs-5 fw-bolder">Download</span>
                            </>
                        )}
                    </button>
                </div>
            </div>
        </>
    );
};
export default Certificates;
