import React, {useState} from "react";
import calculateTimeDifference from "../../../utils/common-functions/DisplayTimeFormat";
import avatarImage from "../../../assets/avatarUser.png";
import {AiFillHeart, AiOutlineHeart} from "react-icons/ai";
import ChatDot from "../../../assets/chat-dot.png";
import AddCommentsReply from "../modal/add-comments-reply";
import {SharedResourcesService} from "../../../services/sharedResourcesService/sharedResourcesServices";

const ResourceAllComments = ({ allComments, selectedResourceId, getResourceDetails, getAllResourceComments, depth = 0 }) => {
    const [showPopup, setShowPopup] = useState(false);
    const [commentId, setCommentId] = useState(null);
    const [likeStatus, setLikeStatus] = useState("");

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const indentation = `${depth * 40}px`;

    const commentContainerStyle = {
        marginLeft: indentation,
        borderLeft: indentation === "0px" ? " " : "5px solid #a07e59",
        padding: indentation === "0px" ? "" : "10px 0px 0px 10px",
        marginTop: indentation === "0px" ? " " : "20px",
        marginBottom: "15px",
        borderRadius: "0px",
    };

    const handleLikeClick = (commentId) => {
        SharedResourcesService.addCommentLike(commentId)
            .then((response) => {
                setLikeStatus(response?.data?.status);
                getAllResourceComments();
            })
            .catch((error) => {});
    };

    const renderAnswer = (answer) => {
        const linkRegex = /(https?:\/\/[^\s]+)/g;
        const parts = answer.split(linkRegex);
        return parts.map((part, index) => {
            if (part.match(linkRegex)) {
                return (
                    <a key={index} href={part} target="_blank" rel="noopener noreferrer" className="fw-bold">
                        {part}
                    </a>
                );
            } else {
                // Remove HTML tags from the text
                const textWithoutTags = part.replace(/(<([^>]+)>)/gi, "");
                return <span key={index}>{textWithoutTags}</span>;
            }
        });
    };

    return (
        <>
            {allComments?.map((comment) => {
                const timeAgo = calculateTimeDifference(comment?.comments?.created_at);
                return (
                    <>
                        <div className="project-comment-container" style={commentContainerStyle} key={comment?.comments?.id}>
                            <div className="d-flex gap-10">
                                {comment?.comments?.user?.full_profile_image !== null ? (
                                    <img crossOrigin="anonymous" height={30} width={30} className="rounded-circle" src={comment?.comments?.user?.full_profile_image}></img>
                                ) : (
                                    <img crossOrigin="anonymous" height={30} width={30} className="rounded-circle" src={avatarImage}></img>
                                )}

                                <div>
                                    <div className="comment-user-name">
                                        {comment?.comments?.user?.first_name}&nbsp;
                                        {comment?.comments?.user?.last_name}
                                    </div>
                                    <div className="comment-time"> Posted {timeAgo}.</div>
                                </div>
                            </div>
                            <div className="mt-2 comment">
                                {renderAnswer(comment?.comments?.comment)?.map((element, index) => (
                                    <React.Fragment key={index}>{element}</React.Fragment>
                                ))}
                            </div>

                            <div className="d-flex mt-2 gap-20">
                                <div className="btn-red">
                                    {likeStatus === true || comment?.comments?.hasUserLiked === true ? (
                                        <AiFillHeart
                                            fill="red"
                                            onClick={() => {
                                                handleLikeClick(comment?.comments?.id);
                                            }}
                                            fontSize={26}
                                            style={{ cursor: "pointer" }}
                                        />
                                    ) : (
                                        <AiOutlineHeart
                                            onClick={() => {
                                                handleLikeClick(comment?.comments?.id);
                                            }}
                                            fontSize={26}
                                            style={{ cursor: "pointer" }}
                                        />
                                    )}
                                </div>
                                <div
                                    className="comment-btn-brown cursor-pointer"
                                    onClick={() => {
                                        setCommentId(comment?.comments?.id);
                                        togglePopup();
                                    }}
                                >
                                    <img crossOrigin="anonymous" src={ChatDot} style={{ marginRight: "10px" }}></img>
                                    Reply
                                </div>
                            </div>
                        </div>

                        {comment?.replies?.length > 0 &&
                            comment?.replies?.map((reply) => (
                                <ResourceAllComments
                                    key={reply?.comments?.id}
                                    allComments={[reply]}
                                    selectedResourceId={selectedResourceId}
                                    getResourceDetails={getResourceDetails}
                                    getAllResourceComments={getAllResourceComments}
                                    depth={depth + 1}
                                />
                            ))}
                    </>
                );
            })}
            {showPopup && (
                <AddCommentsReply
                    handleClosePopup={handleClosePopup}
                    selectedResourceId={selectedResourceId}
                    getAllResourceComments={getAllResourceComments}
                    getResourceDetails={getResourceDetails}
                    commentId={commentId}
                />
            )}
        </>
    );
};

export default ResourceAllComments;