import React, { useEffect } from "react";
import { FiArrowUpRight } from "react-icons/fi";
import { PiLockSimpleLight } from "react-icons/pi";
import moment from "moment";
import Logo from "../../../../assets/paxton-patterson.jpeg";
import { useNavigate } from "react-router-dom";
import { MEETING_TYPES, ROUTES } from "../../../../constants";
import convertUtcToSelectedTimeZone from "../../../../utils/common-functions/ConvertUtcToTimezone";

const LockedSessionCard = ({ session, flag }) => {
    const navigate = useNavigate();

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const handleSession = () => {
        if (flag) {
            navigate("/" + ROUTES.meetingPage.replace(":type", MEETING_TYPES.session).replace(":id", session.key_note_id));
        }
        scrollToTop();
    };

    const isSessionUnlocked = convertUtcToSelectedTimeZone(session?.start_date) <= moment();

    return (
        <div className="position-relative" onClick={handleSession} style={{ cursor: flag ? "pointer" : "not-allowed" }}>
            <div className={`row align-items-lg-center gap-3 inner-card-layout-locked-session py-3 px-2 mt-4 ${isSessionUnlocked && flag ? "opacity-100" : ""}`}>
                <img crossOrigin="anonymous" src={session?.key_note?.full_cover_image} alt="" className="col-lg-5 col-md-5 col-sm-12 col-xs-12  session-cover-img" />
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 session-right-content d-flex flex-column justify-content-start align-items-start">
                    <img crossOrigin="anonymous" src={Logo} alt="" />
                    <div className="ms-2">
                        <div className="session-masterclass-name mt-2">{session.key_note?.master_class?.name}</div>
                        <div className="session-keynote-name mb-3">{session?.key_note?.name}</div>
                        <button className="watch-session-btn" disabled={!isSessionUnlocked}>
                            <FiArrowUpRight className="fs-5 me-2" />
                            Watch Now
                        </button>
                    </div>
                </div>
            </div>

            {isSessionUnlocked && flag ? null : (
                <div className="lock-overlay" style={{ cursor: flag ? "pointer" : "not-allowed" }}>
                    <div className="d-flex gap-2 justify-content-center align-items-center">
                        <PiLockSimpleLight className="fs-1" />
                        <div>
                            <div className="session-lock-subtext">Course will be available:</div>
                            <div className="session-lock-date">{moment(session?.start_date).format("Do MMM YYYY")}</div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LockedSessionCard;
