/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import "../notification.css";
import convertCreatedAtToTimeAgo from "../../../utils/common-functions/ConvertCreatedAtToTimeAgo";
import { Link } from "react-router-dom";
import defaultImage from "../../../assets/paxton-patterson-logo.png";
import notFound from "../../../assets/not-found/not_found.png";
import NotificationLoader from "../../../components/common/NotificationLoader";

const NotificationsDropdown = ({ iconRef, allNotifications, isLoading }) => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    const handleViewAllClick = () => {
        scrollToTop();
    };

    return (
        <>
            <div className={`dashboard-dropdown dropdown-menu dropdown-mobile`} aria-labelledby="notificatioDropdown">
                <div
                    className=" menu-item fs-6 p-3 text-white d-flex justify-content-between rounded-top"
                    style={{
                        backgroundColor: "rgba(28, 34, 55, 1)",
                    }}
                >
                    <span>Notifications</span>
                    <Link to="/notification" className="text-white" onClick={handleViewAllClick}>
                        View All
                    </Link>
                </div>
                <div className="separator border-gray-400 my-0"></div>
                <div className="menu-item" style={{ maxHeight: "300px", overflowY: "auto" }}>
                    {isLoading ? (
                        <NotificationLoader />
                    ) : (
                        <div className="menu-content">
                            {allNotifications?.length ? (
                                allNotifications?.map((notification) => (
                                    <div key={notification.id} className={`menu-item rounded px-3 py-3 mb-3 mt-2 `}>
                                        <div className="d-flex align-items-center">
                                            <div className="symbol symbol-circle symbol-40px me-2">
                                                <img src={defaultImage} alt="user_profile" className="notify-user-image" />
                                            </div>
                                            <div className="d-flex flex-column flex-grow-1">
                                                <span className="fw-bold noti-title">{notification.notifications.title}</span>
                                                <span className="text-muted mt-1 noti-message">{notification.notifications.message}</span>
                                                <span className=" text-muted noti-time mt-1">{convertCreatedAtToTimeAgo(notification.notifications.created_at)}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="w-100 shadow p-3 mt-3 rounded-4 text-center">
                                    <img crossOrigin="anonymous" src={notFound} alt="data not found" width={200} />
                                    <h5>No new notifications added</h5>
                                    <span className="fs-6 fw-lighter">No new notifications at the moment.</span>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default NotificationsDropdown;
