import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: "auth",
    initialState: {
        isLoggedIn: false,
        auth: null,
        email: null,
        userData: {},
        keynoteDate: null,
        progressBar: 0,
        accessToken: null,
        discussionCategories: [],
    },
    reducers: {
        setAccessToken: (state, action) => {
            state.accessToken = action.payload;
        },
        loginSuccess: (state, action) => {
            state.isLoggedIn = true;
            state.auth = action.payload;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setUserData: (state, action) => {
            state.userData = action.payload;
        },
        setDiscussionCategories: (state, action) => {
            state.discussionCategories = action.payload;
        },
        setUpcomingKeyNoteDate: (state, action) => {
            state.keynoteDate = action.payload;
        },
        setProgressBar: (state, action) => {
            state.progressBar = action.payload;
        },
        setWatchedAt: (state, action) => {
            state.userData = {
                ...state.userData,
                welcomed_at: action.payload,
            };
        },
        logout: (state) => {
            state.accessToken = null;
            state.isLoggedIn = false;
            state.auth = null;
            state.email = null;
            state.userData = null;
            state.keynoteDate = null;
            state.progressBar = 0;
        },
    },
});

export const { setAccessToken, loginSuccess, logout, setEmail, setUserData, setUpcomingKeyNoteDate, setProgressBar, setDiscussionCategories, setWatchedAt } = authSlice.actions;
export default authSlice.reducer;
