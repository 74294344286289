import apiClient from "../../utils/apiClient";

const ADD_RESOURCE = "/shared-resources";
const GET_ALL_RESOURCES = "/shared-resources";
const DELETE_SHARED_RESOURCE = "/shared-resources";
const UPDATE_RESOURCE = "/shared-resources";

const addResource = (data) => {
    const url = `${ADD_RESOURCE}`;
    return apiClient.post(url, data);
};

const getAllResources = (limit, page) => {
    const url = `${GET_ALL_RESOURCES}?limit=${limit}&page=${page}`;
    return apiClient.get(url);
};

const deleteResource = (id) => {
    const url = `${DELETE_SHARED_RESOURCE}/${id}`;
    return apiClient.delete(url);
};

const getAllComments = (id) => {
    const url = `shared-resources/${id}/comments`;
    return apiClient.get(url);
};

const getResourceDetails = (id) => {
    const url = `shared-resources/${id}`;
    return apiClient.get(url);
};

const addLike = (id) => {
    const url = `shared-resources/${id}/like`;
    return apiClient.post(url);
};

const addCommentLike = (id) => {
    const url = `/shared-resources/comments/${id}/like`;
    return apiClient.post(url);
};

const addComments = (id, data) => {
    const url = `/shared-resources/${id}/comments`;
    return apiClient.post(url, data);
};

const updateResourceData = (id, data) => {
    const url = `${UPDATE_RESOURCE}/${id}`;
    return apiClient.put(url, data);
};

export const SharedResourcesService = {
    addResource,
    getAllResources,
    deleteResource,
    getAllComments,
    getResourceDetails,
    addLike,
    addCommentLike,
    addComments,
    updateResourceData
};
