import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import "./community.css";
import Discussion from "./components/discussion";
import { communityService } from "../../services/communityService/communityService";
import AddPost from "./modal/add-post";
import Loader from "../../components/common/Loader";
import Pagination from "@mui/material/Pagination";
import { AiOutlinePlus } from "react-icons/ai";
import notFound from "../../assets/not-found/not_found.png";

const GeneralDiscussion = ({ categoryId = null, categoryTitle = 'General Discussion' }) => {
    const limit = 5;
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const handleChange = (event, value) => {
        setPage(value);
    };
    const [discussions, setDiscussions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getGeneralDiscussion = () => {
        setIsLoading(true);
        communityService.getGeneralDiscussion(page, limit, categoryId).then((res) => {
            setDiscussions(res?.data?.data);
            setIsLoading(false);
            setTotal(Math.ceil(res?.data?.total_records / limit));
        });
    };

    useEffect(() => {
        getGeneralDiscussion();
    }, [page, categoryId]);

    const [showAddModal, setShowAddModal] = useState(false);

    const handleAddPost = () => {
        setShowAddModal(true);
    };

    const closeAddPost = () => {
        setShowAddModal(false);
        setPage(0);
    };

    const handleAddPostData = (questionId, data) => {
        let tempDiscussion = JSON.parse(JSON.stringify(discussions));
        if (data) {
            tempDiscussion = tempDiscussion.map((r) => {
                if (r.general_questions.id == questionId) {
                    r.general_questions.general_community_discussion = data.data.data;
                }
                return r;
            });
            setDiscussions(tempDiscussion);
        }
    };

    return (
        <>
            <div className="d-flex justify-space-between align-center">
                <h3 className="d-lg-block d-none" style={{ marginBottom: "0px" }}>
                    {categoryTitle}
                </h3>

                <Button variant="primary" onClick={() => handleAddPost()}>
                    <div className="d-flex justify-space-between align-center">
                        <AiOutlinePlus style={{ fontSize: "22px", marginRight: "10px" }} />
                        Start a new topic
                    </div>
                </Button>
            </div>
            {isLoading ? (
                <Loader></Loader>
            ) : (
                <>
                    <div className="outer-card-layout mt-4">
                        {discussions?.length > 0 ? (
                            <>
                                {discussions.map((discussion, key) => {
                                    return (
                                        <div key={key}>
                                            <Discussion
                                                key={key}
                                                isFirst={key == 0 ? true : false}
                                                discussion={discussion}
                                                handleAddPostData={handleAddPostData}
                                                getGeneralDiscussion={getGeneralDiscussion}
                                            />
                                            {key != discussions.length - 1 && <hr style={{ marginBottom: ".5rem" }} />}
                                        </div>
                                    );
                                })}
                                {total > 0 && (
                                    <div className="pagination-div">
                                        <Pagination count={total} page={page} onChange={handleChange} />
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="w-100 mt-3 rounded-4 text-center d-flex flex-column align-items-center justify-content-center" style={{ height: "400px" }}>
                                <img crossOrigin="anonymous" src={notFound} alt="data not found" width={300} />
                                <h4>No {categoryTitle} Added</h4>
                                <p className="fs-6">No {categoryTitle} available at the moment.</p>
                            </div>
                        )}
                    </div>
                </>
            )}
            {showAddModal && <AddPost onClose={closeAddPost} isComment={false} getGeneralDiscussion={getGeneralDiscussion} categoryId={categoryId} />}
        </>
    );
};
export default GeneralDiscussion;
