/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import AddPlusIcon from "../../../assets/add_plus_icon 2.svg";
import AddMoreProjects from "../modal/add-project";
import "../projects.css";
import ShowProjectsDetails from "./ShowProjectsDetails";
import { ProjectsService } from "../../../services/projectsService/ProjectsService";
import Loader from "../../../components/common/Loader";
import notFound from "../../../assets/not-found/not_found.png";
import defaultImage from "../../../assets/avatarUser.png";
import Pagination from "@mui/material/Pagination";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { FaRegCommentDots } from "react-icons/fa";
import { FiEye } from "react-icons/fi";
import calculateTimeDifference from "../../../utils/common-functions/DisplayTimeFormat";
import { useNavigate } from "react-router-dom";
import imageNotFound from "../../../assets/paxton-patterson-logo.png";
import {BsTrash} from "react-icons/bs";
import {useSelector} from "react-redux";
import {SharedResourcesService} from "../../../services/sharedResourcesService/sharedResourcesServices";
import Swal from "sweetalert2";

const MyProjects = () => {
    const [myKey, setMyKey] = useState("myProjects");
    const [showAddModal, setShowAddModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [projectsData, setProjectsData] = useState([]);
    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const [mediaType, setMediaType] = useState([]);
    const navigate = useNavigate();
    const [likeStatus, setLikeStatus] = useState("");
    const userId = useSelector((state) => state?.auth?.userData?.id);

    const limit = 12;
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    // call api for get my projects data
    const myProjectsData = () => {
        setIsLoading(true);
        ProjectsService.getMyProjectsData(limit, page).then((response) => {
            setProjectsData(response?.data?.data);
            setTotal(Math.ceil(response?.data?.total_records / limit));
            setIsLoading(false);
        });
    };

    // Handle the click on a project card
    const handleProjectClick = (id) => {
        ProjectsService.addView({ project_id: id }).then((response) => {});
        navigate(`/impacts/my-impacts/${id}`, { state: "myProject" });
        scrollToTop();
    };

    // useEffect of call all api function
    useEffect(() => {
        myProjectsData();
    }, [page]);

    const handleAddProjects = () => {
        setShowAddModal(true);
    };

    const closeAddModal = () => {
        setShowAddModal(false);
    };

    const handleChange = (event, value) => {
        setPage(value);
    };

    const handleDeleteConfirmation = (event, id) => {
        event.stopPropagation();

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                handleDelete(id);
            }
        });
    };

    const handleDelete = (id) => {
        ProjectsService
            .deleteProject(id)
            .then((res) => {
                if (res) {
                    Swal.fire("Deleted!", "Your project has been deleted.", "success");
                    myProjectsData();
                }
            })
            .catch((error) => {
                Swal.fire("Error!", "Failed to delete the project.", "error");
            });
    };

    return (
        <>
            <>
                <div className="d-flex justify-content-between project-header flex-column">
                    <div className="d-flex justify-content-between align-items-center">
                        <h3 className="d-lg-block m-0 d-none">My Impact</h3>
                        <div className="d-flex justify-end">
                            <div
                                className="add-new-project"
                                onClick={() => {
                                    handleAddProjects();
                                }}
                            >
                                <img crossOrigin="anonymous" src={AddPlusIcon} style={{ marginRight: "10px" }}></img>
                                <span style={{ position: "relative", top: "3px" }}>Add New Impact</span>
                            </div>
                        </div>
                    </div>
                    <span className="mt-3">
                        This is where you can share the impact Paxton Patterson has helped you to have on your students and on your institution. Upload images, flyers, content, and descriptions to share and
                        inspire others.
                    </span>
                </div>
            </>
            <div className="outer-card-layout mt-4 all-projects-layout">
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        {myKey == "myProjects" && (
                            <>
                                {projectsData?.length > 0 ? (
                                    <div className="row mt-4">
                                        {projectsData?.map((data) => {
                                            const timeAgo = calculateTimeDifference(data?.created_at);
                                            return (
                                                <div className="col-md-4 col-lg-4 col-sm-6 mb-4 cursor-pointer" onClick={() => handleProjectClick(data.id)}>
                                                    <div className="margin-lr">
                                                        <>
                                                            <div className="all-project-image">
                                                                {data?.full_cover_image === null ? (
                                                                    <img
                                                                        crossOrigin="anonymous"
                                                                        className="projects-cover-image"
                                                                        src={imageNotFound}
                                                                        alt="Cover Image"
                                                                        style={{ border: "1px solid black" }}
                                                                    />
                                                                ) : (
                                                                    <img crossOrigin="anonymous" className="projects-cover-image" src={data?.full_cover_image} alt="Cover Image" />
                                                                )}
                                                            </div>
                                                            <div className="d-flex align-items-center gap-20 image-info">
                                                                <div>
                                                                    {likeStatus === true || data?.hasUserLiked === 1 ? <AiFillHeart fill="red" fontSize={20} /> : <AiOutlineHeart fontSize={20} />}

                                                                    <span className="image-text-span fs-6">{data?.project_likes}</span>
                                                                </div>
                                                                <div>
                                                                    <FaRegCommentDots fontSize={20} />
                                                                    <span className="image-text-span fs-6">{data?.project_comments}</span>
                                                                </div>
                                                                <div>
                                                                    <FiEye fontSize={20} />
                                                                    <span className="image-text-span fs-6">{data?.project_views}</span>
                                                                </div>
                                                                {userId === data.user.id &&
                                                                    <BsTrash className="cursor-pointer" fill="#aa0000" fontSize={20}
                                                                             onClick={(event) => handleDeleteConfirmation(event, data.id)}/>
                                                                }
                                                            </div>
                                                            <div className="all-project-name mt-2">{data?.title}</div>
                                                            <div className="all-project-detail d-lg-block">
                                                                <div className="d-flex gap-2">
                                                                    {data?.user?.full_profile_image !== null ? (
                                                                        <img crossOrigin="anonymous" className="user-profile-image" src={data?.user?.full_profile_image}></img>
                                                                    ) : (
                                                                        <img crossOrigin="anonymous" className="user-profile-image" src={defaultImage}></img>
                                                                    )}
                                                                    <div className="project-author-name">
                                                                        {data?.user?.first_name}&nbsp;{data?.user?.last_name}
                                                                    </div>
                                                                </div>

                                                                <div className="all-project-time mt-1">Posted - {timeAgo}.</div>
                                                            </div>
                                                        </>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <div className="w-100 mt-3 rounded-4 text-center d-flex flex-column align-items-center justify-content-center" style={{ height: "500px" }}>
                                        <img crossOrigin="anonymous" src={notFound} alt="data not found" width={300} />
                                        <h3>No Impacts Added</h3>
                                        <p className="fs-5">No Impacts available at the moment.</p>
                                    </div>
                                )}
                            </>
                        )}
                        {myKey == "myProject" && (
                            <>
                                <ShowProjectsDetails
                                    myProjectsData={myProjectsData}
                                    setMyKey={setMyKey}
                                    title={myKey}
                                    selectedProjectId={selectedProjectId}
                                    projectsData={projectsData}
                                    mediaType={mediaType}
                                />
                            </>
                        )}
                    </>
                )}
                {myKey == "myProjects" && total > 0 && (
                    <div className="pagination-div my-2">
                        <Pagination count={total} page={page} onChange={handleChange} />
                    </div>
                )}
                {showAddModal && <AddMoreProjects onClose={closeAddModal} myProjectsData={myProjectsData} mediaType={mediaType} />}
            </div>
        </>
    );
};
export default MyProjects;
