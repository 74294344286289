/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import LeftIcon from "../../../assets/left-icon.png";
import RightIcon from "../../../assets/right-icon.svg";
import defaultImage from "../../../assets/avatarUser.png";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { FaRegCommentDots } from "react-icons/fa";
import { FiEye } from "react-icons/fi";
import Pagination from "@mui/material/Pagination";
import calculateTimeDifference from "../../../utils/common-functions/DisplayTimeFormat";
import { IconButton } from "@mui/material";
import "../projects.css";
import imageNotFound from "../../../assets/paxton-patterson-logo.png";

const ShowMoreProjects = ({ selectedProject, otherProjects, onProjectClick }) => {
    const [likeStatus, setLikeStatus] = useState("");
    const limit = 3;
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        setTotal(Math.ceil(otherProjects?.length / limit));
    }, []);

    // const total = otherProjects?.length;

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const handleProjectClick = (projectId) => {
        onProjectClick(projectId);
        scrollToTop();
    };

    const handleChange = (event, value) => {
        setPage(value);
    };

    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    const projectsForCurrentPage = otherProjects?.slice(startIndex, endIndex);

    return (
        <>
            <div className="mt-4 more-projects">
                More Impacts by{" "}
                <span style={{ color: "#FF9494" }}>
                    {selectedProject?.user?.first_name}&nbsp;{selectedProject?.user?.last_name}
                </span>
            </div>
            <div className="row mt-lg-4 mt-md-4 mt-4 all-projects-layout ">
                {projectsForCurrentPage?.map((e) => {
                    const timeAgo = calculateTimeDifference(e?.created_at);
                    return (
                        <div className="col-md-4 col-lg-6 col-xl-4 col-sm-6 mt-3 mt-lg-4 mt-md-4 cursor-pointer mobile-view" onClick={() => handleProjectClick(e.id)} key={e?.id}>
                            <div className="margin-lr">
                                <>
                                    <div className="all-project-image">
                                        {e?.full_cover_image === null ? (
                                            <img crossOrigin="anonymous" className="projects-cover-image" src={imageNotFound} alt="Cover Image" style={{ border: "1px solid black" }} />
                                        ) : (
                                            <img crossOrigin="anonymous" className="projects-cover-image" src={e?.full_cover_image} alt="Cover Image" />
                                        )}
                                    </div>
                                    <div className="d-flex gap-20 image-info">
                                        <div>
                                            {likeStatus === true || e?.hasUserLiked === 1 ? <AiFillHeart fill="red" fontSize={20} /> : <AiOutlineHeart fontSize={20} />}

                                            <span className="image-text-span fs-6">{e?.project_likes}</span>
                                        </div>
                                        <div>
                                            <FaRegCommentDots fontSize={20} />
                                            <span className="image-text-span fs-6">{e?.project_comments}</span>
                                        </div>
                                        <div>
                                            <FiEye fontSize={20} />
                                            <span className="image-text-span fs-6">{e?.project_views}</span>
                                        </div>
                                    </div>
                                    <div className="all-project-name">{e?.title}</div>
                                    <div className="all-project-detail d-lg-block">
                                        <div className="d-flex gap-2">
                                            {e?.user?.full_profile_image !== null ? (
                                                <img crossOrigin="anonymous" className="user-profile-image" src={e?.user?.full_profile_image}></img>
                                            ) : (
                                                <img crossOrigin="anonymous" className="user-profile-image" src={defaultImage}></img>
                                            )}
                                            <div className="project-author-name">
                                                {e?.user?.first_name}&nbsp;{e?.user?.last_name}
                                            </div>
                                        </div>

                                        <div className="all-project-time">Posted - {timeAgo}.</div>
                                    </div>
                                </>
                            </div>
                        </div>
                    );
                })}
            </div>

            {otherProjects?.length > 3 ? (
                // <div className="mt-4 d-flex gap-20">
                //     <div className="brd"></div>
                //     <div className="d-flex gap-10">
                //         <img crossOrigin="anonymous" src={LeftIcon} className="page-change-icon-left" onClick={handleRightIconClick}></img>
                //         <img crossOrigin="anonymous" src={RightIcon} className="page-change-icon-right" onClick={handleRightIconClick}></img>
                //     </div>
                //     <div className="brd"></div>
                // </div>
                <div className="pagination-div ">
                    <div className="brd"></div>
                    <Pagination
                        count={total}
                        className="more-paginations"
                        page={page}
                        onChange={handleChange}
                        boundaryCount={0}
                        renderItem={(item) => {
                            switch (item.type) {
                                case "page":
                                    return null;
                                case "start-ellipsis":
                                case "end-ellipsis":
                                    return <span className="ellipsis">...</span>;
                                default:
                                    return (
                                        <IconButton
                                            onClick={() => handleChange(null, item.page)}
                                            disabled={(item.type === "previous" && page === 1) || (item.type === "next" && page === total)}
                                            selected={item.page === page}
                                            className={item.type === "previous" && page === 1 ? "opacity-50" : item.type === "next" && page === total ? "opacity-50" : ""}
                                        >
                                            {item.type === "previous" ? (
                                                <img crossOrigin="anonymous" src={LeftIcon} className="page-change-icon-left"></img>
                                            ) : (
                                                <img crossOrigin="anonymous" src={RightIcon} className="page-change-icon-right"></img>
                                            )}
                                        </IconButton>
                                    );
                            }
                        }}
                    />
                    <div className="brd"></div>
                </div>
            ) : (
                <div className="brd w-100"></div>
            )}
        </>
    );
};

export default ShowMoreProjects;
