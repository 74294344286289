import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import OuterLayout from "../layout/OuterLayout/OuterLayout";
import Dashboard from "../pages/dashboard/index";
import LoginContentSection from "../pages/auth/components/login/LoginContentSection";
import ForgotContentSection from "../pages/auth/components/forgot-password/ForgotContentSection";
import ResetContentSection from "../pages/auth/components/reset-password/ResetContentSection";
import PublicRoute from "./PublicRoute";
import ProtectedRoute from "./ProtectedRoute";
import ProfileSettings from "../pages/profile-settings";
import OtpVerifyContentSection from "../pages/auth/components/otpVerification/OtpVerifyContentSection";
import ScheduleCalendar from "../pages/ScheduleCalendar/ScheduleCalendar";
import Sessions from "../pages/sessions";
import MiniCourses from "../pages/playbook-mini-courses";
import Community from "../pages/community";
import Certificates from "../pages/certificates";
import Chat from "../pages/chat";
import GeneralDiscussion from "../pages/general-discussion";
import InnerLayout from "../layout/InnerLayout";
import AllProjects from "../pages/projects/components/AllProjects";
import MyProjects from "../pages/projects/components/MyProjects";

import RecordedSession from "../pages/recorded-session";
import DownloadApp from "../pages/download-app/DownloadApp";
import { ROUTES } from "../constants";
import ShowProjectsDetails from "../pages/projects/components/ShowProjectsDetails";
import ReportsPage from "../pages/reports";
import SpecialEvent from "../pages/special-events";
import Notification from "../pages/notifications/Notification";
import { useSelector } from "react-redux";
import AllSharedResources from "../pages/shared-resources/components/AllSharedResources";
import ShowResourceDetails from "../pages/shared-resources/components/ShowResourceDetails";

const AppRoutes = () => {
    const [isPreviousSession, setIsPreviousSession] = useState(false);
    const token = useSelector((state) => state?.auth?.accessToken);
    const discussionCategories = useSelector((state) => state?.auth?.discussionCategories);

    return (
        <Routes>
            {/* Auth routes start */}
            <Route path="/" element={<OuterLayout />}>
                <Route
                    path="/"
                    element={
                        <PublicRoute>
                            <LoginContentSection />
                        </PublicRoute>
                    }
                />
                <Route
                    path="forgot-password"
                    element={
                        <PublicRoute>
                            <ForgotContentSection />
                        </PublicRoute>
                    }
                />
                <Route
                    path="verify-otp"
                    element={
                        <PublicRoute>
                            <OtpVerifyContentSection />
                        </PublicRoute>
                    }
                />
                <Route
                    path="reset-password"
                    element={
                        <PublicRoute>
                            <ResetContentSection />
                        </PublicRoute>
                    }
                />
                {token === null && (
                    <>
                        <Route
                            path="dashboard/live-event/:id"
                            element={
                                <PublicRoute>
                                    <LoginContentSection />
                                </PublicRoute>
                            }
                        />
                        <Route
                            path="dashboard/coaching-call/:id"
                            element={
                                <PublicRoute>
                                    <LoginContentSection />
                                </PublicRoute>
                            }
                        />
                        <Route
                            path="dashboard/masterclass-discussion/:id"
                            element={
                                <PublicRoute>
                                    <LoginContentSection />
                                </PublicRoute>
                            }
                        />
                        <Route
                            path="dashboard/session/:id"
                            element={
                                <PublicRoute>
                                    <LoginContentSection />
                                </PublicRoute>
                            }
                        />
                    </>
                )}
            </Route>
            {/* Auth routes end */}

            {/* Inner routes start */}
            <Route path="" element={<InnerLayout />}>
                <Route
                    path={ROUTES.dashboard}
                    element={
                        <ProtectedRoute>
                            <Dashboard setIsPreviousSession={setIsPreviousSession} isPreviousSession={isPreviousSession} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ROUTES.meetingPage}
                    element={
                        <ProtectedRoute>
                            <Dashboard setIsPreviousSession={setIsPreviousSession} isPreviousSession={isPreviousSession} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="profile-settings"
                    element={
                        <ProtectedRoute>
                            <ProfileSettings />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="calendar"
                    element={
                        <ProtectedRoute>
                            <ScheduleCalendar />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="keynotes"
                    element={
                        <ProtectedRoute>
                            <Sessions setIsPreviousSession={setIsPreviousSession} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="community/keynote-discussion"
                    element={
                        <ProtectedRoute>
                            <Community />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="community/general-discussion"
                    element={
                        <ProtectedRoute>
                            <GeneralDiscussion />
                        </ProtectedRoute>
                    }
                />
                {discussionCategories.map((category) => (
                    <Route
                        path={`community/${category.slug}`}
                        element={
                            <ProtectedRoute>
                                <GeneralDiscussion
                                    categoryId={category.id}
                                    categoryTitle={category.title}
                                />
                            </ProtectedRoute>
                        }
                    />
                ))}
                <Route
                    path="impacts/my-impacts"
                    element={
                        <ProtectedRoute>
                            <MyProjects />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="impacts/all-impacts"
                    element={
                        <ProtectedRoute>
                            <AllProjects />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="impacts/my-impacts/:impactId"
                    element={
                        <ProtectedRoute>
                            <ShowProjectsDetails />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="impacts/all-impacts/:impactId"
                    element={
                        <ProtectedRoute>
                            <ShowProjectsDetails />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="certificate"
                    element={
                        <ProtectedRoute>
                            <Certificates />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="technical-assistant"
                    element={
                        <ProtectedRoute>
                            <Chat />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="playbook"
                    element={
                        <ProtectedRoute>
                            <MiniCourses />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="recorded-sessions"
                    element={
                        <ProtectedRoute>
                            <RecordedSession />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="download-app"
                    element={
                        <ProtectedRoute>
                            <DownloadApp />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="reports"
                    element={
                        <ProtectedRoute>
                            <ReportsPage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="live-events"
                    element={
                        <ProtectedRoute>
                            <SpecialEvent />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="notification"
                    element={
                        <ProtectedRoute>
                            <Notification />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="community/shared-resources"
                    element={
                        <ProtectedRoute>
                            <AllSharedResources />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="community/shared-resources/:resourceId"
                    element={
                        <ProtectedRoute>
                            <ShowResourceDetails />
                        </ProtectedRoute>
                    }
                />
            </Route>
            {/* Inner routes end */}
        </Routes>
    );
};

export default AppRoutes;
