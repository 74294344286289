export const liveEventTypes = {
    'Conferences': {
        value: 1,
        color: "#8bc34a"
    },
    'Best Practice Showcases': {
        value: 2,
        color: "#eca843"
    },
    'Other': {
        value: 3,
        color: "#d573f6"
    },
};