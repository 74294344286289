import {useSelector} from "react-redux";
import React, {useState} from "react";
import {toast} from "react-toastify";
import avatarImage from "../../../assets/avatarUser.png";
import ReactQuill from "react-quill";
import {SharedResourcesService} from "../../../services/sharedResourcesService/sharedResourcesServices";

const AddComments = ({ selectedResourceId, getAllResourceComments, getResourceDetails }) => {
    const userData = useSelector((state) => state?.auth?.userData);
    const [comment, setComment] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const handleCommentChange = (newComment) => {
        setIsButtonDisabled(false);
        setComment(newComment);
    };

    const addCommentData = async () => {
        setIsButtonDisabled(true);

        if (comment.replace(/\s/g, "") === "<p></p>") {
            toast.error("Invalid Input, Please avoid leading spaces at the beginning of the field");
        } else {
            const data = {
                parent_id: "",
                comment: comment,
            };

            await SharedResourcesService.addComments(selectedResourceId, data);
            toast.success("Your comment has been added successfully");
            setComment("");

            getAllResourceComments();
            getResourceDetails();
        }
    };

    return (
        <div className="add-comments-popup py-1  py-lg-4 py-md-4">
            <div className="d-flex gap-3 align-items-center">
                {userData?.full_profile_image !== null ? (
                    <img crossOrigin="anonymous" height={30} width={30} className="rounded-circle" src={userData?.full_profile_image}></img>
                ) : (
                    <img crossOrigin="anonymous" height={30} width={30} className="rounded-circle" src={avatarImage}></img>
                )}

                <span className="fs-6 fw-bold">Add a Comment</span>
            </div>
            <div className="mt-4">
                <ReactQuill theme="snow" value={comment} onChange={handleCommentChange} placeholder="Write your comment here" />
            </div>
            <div className="mt-4">
                <button className="post-comment-btn btn btn-primary" onClick={addCommentData} disabled={isButtonDisabled}>
                    Post Comment
                </button>
            </div>
        </div>
    );
};

export default AddComments;