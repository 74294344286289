import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import calculateTimeDifference from "../../../utils/common-functions/DisplayTimeFormat";
import Loader from "../../../components/common/Loader";
import ArrowBack from "../../../assets/arrow-back.png";
import {FiEdit} from "react-icons/fi";
import defaultImage from "../../../assets/avatarUser.png";
import {AiFillHeart, AiOutlineHeart} from "react-icons/ai";
import {FaRegCommentDots} from "react-icons/fa";
import avatarImage from "../../../assets/profile-settings/avatarImage.jpg";
import Chat2Icon from "../../../assets/chat-icon.svg";
import AddComments from "../modal/add-comments";
import {SharedResourcesService} from "../../../services/sharedResourcesService/sharedResourcesServices";
import ResourceAllComments from "./ResourceAllComments";
import EditResource from "../modal/edit-resource";
import {toast} from "react-toastify";
import {BsCloudArrowDown} from "react-icons/bs";
import {useSelector} from "react-redux";

const ShowResourceDetails = () => {
    const [showAddModal, setShowAddModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedResource, setSelectedResource] = useState(null);
    const [likeStatus, setLikeStatus] = useState("");
    const [allComments, setAllComments] = useState([]);
    const userId = useSelector((state) => state?.auth?.userData?.id);
    const { resourceId } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();

    const getAllResourceComments = () => {
        SharedResourcesService.getAllComments(resourceId).then((response) => {
            setAllComments(response?.data?.data);
        });
    };

    const getResourceDetails = () => {
        SharedResourcesService.getResourceDetails(resourceId).then((response) => {
            setSelectedResource(response?.data?.data);
        });
    };

    // useEffect of call all api function
    useEffect(() => {
        getResourceDetails();
        getAllResourceComments();
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    const handleBack = () => {
        navigate(-1);
        scrollToTop();
    };

    const handleEditResource = () => {
        setShowAddModal(true);
    };

    const closeAddModal = () => {
        setShowAddModal(false);
    };

    if (!selectedResource) {
        return null;
    }

    const handleLikeClick = (resourceId) => {
        SharedResourcesService.addLike(resourceId)
            .then((response) => {
                setLikeStatus(response?.data?.status);
                getResourceDetails();
            })
            .catch((error) => {});
    };

    const timeAgo = calculateTimeDifference(selectedResource?.created_at);

    const renderAnswer = (answer) => {
        const linkRegex = /(https?:\/\/[^\s]+)/g;
        const parts = answer.split(linkRegex);
        return parts.map((part, index) => {
            if (part.match(linkRegex)) {
                return (
                    <a key={index} href={part} target="_blank" rel="noopener noreferrer" className="fw-bold">
                        {part}
                    </a>
                );
            } else {
                // Remove HTML tags from the text
                const textWithoutTags = part.replace(/(<([^>]+)>)/gi, "");
                return <span key={index}>{textWithoutTags}</span>;
            }
        });
    };

    const handleDownload = async () => {
        try {
            const response = await fetch(selectedResource.full_resource_path);
            const blob = await response.blob();
            const filename = (selectedResource?.name || "resource_file").replace(/[^\w\s]/g, "_").replace(/\s+/g, "_");
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = filename;
            toast.success(`"${filename}" downloaded successfully`);
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    };

    return (
        <>
            {isLoading ? (
                <>
                    <Loader />
                </>
            ) : (
                <>
                    <div className="outer-card-layout show-all-projects-details">
                        <div className="d-flex justify-space-between mt-0 mt-lg-3 mt-md-3 my-project-btn align-items-center show-projects">
                            <div
                                onClick={() => {
                                    handleBack();
                                }}
                            >
                                <div className="back-button" style={{ width: "max-content" }}>
                                    <img crossOrigin="anonymous" src={ArrowBack} style={{ marginRight: "10px" }}></img>
                                    Back
                                </div>
                            </div>
                            {userId !== selectedResource.user.id ? (
                                <></>
                            ) : (
                                <div
                                    onClick={() => {
                                        handleEditResource();
                                    }}
                                >
                                    <div className="edit-button" style={{ width: "max-content" }}>
                                        <FiEdit />
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="row mt-4 mb-3 project-container">
                            <div className="d-flex justify-space-between">
                                <div className="project-title">{selectedResource?.name}</div>
                                <button className="btn resource-btn-download"
                                        onClick={() => handleDownload()}>
                                    <BsCloudArrowDown fontSize="40" fill="green" />
                                </button>
                            </div>
                            <div className="project-detail mt-4">
                                <div className="row p-0 gap-2 px-lg-3 px-md-3 w-50 align-items-center">
                                    <div className="col-1 ">
                                        {selectedResource?.user?.full_profile_image !== null ? (
                                            <img crossOrigin="anonymous" className="user-profile-image"
                                                 src={selectedResource?.user?.full_profile_image}></img>
                                        ) : (
                                            <img crossOrigin="anonymous" className="user-profile-image"
                                                 src={defaultImage}></img>
                                        )}
                                    </div>
                                    <div className="col-7 mt-1 col-lg-4 col-md-4">
                                        <div className="project-author-name">
                                            {selectedResource?.user?.first_name}&nbsp;{selectedResource?.user?.last_name}
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 col-md-6 mt-2 mt-lg-1 mt-md-1">
                                        <div className="all-project-time">Posted - {timeAgo}.</div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex gap-20 mt-3" style={{gap: "20px"}}>
                                <div>
                                    {likeStatus === true || selectedResource?.hasUserLiked === 1 ? (
                                        <AiFillHeart
                                            fill="red"
                                            onClick={() => {
                                                handleLikeClick(selectedResource?.id);
                                            }}
                                            fontSize={22}
                                            style={{cursor: "pointer"}}
                                        />
                                    ) : (
                                        <AiOutlineHeart
                                            onClick={() => {
                                                handleLikeClick(selectedResource?.id);
                                            }}
                                            fontSize={22}
                                            style={{cursor: "pointer"}}
                                        />
                                    )}

                                    <span className="image-text-span fs-6">{selectedResource?.likes}</span>
                                </div>

                                <div>
                                    <FaRegCommentDots fontSize={22}/>
                                    <span className="image-text-span fs-6">{selectedResource?.comments}</span>
                                </div>
                            </div>
                            <hr className="mt-4" style={{borderColor: "#B7B7FE"}}></hr>
                            <div>
                                <div className="project-heading">Resource Description</div>
                                {selectedResource?.description &&
                                    renderAnswer(selectedResource?.description)?.map((element, index) =>
                                        <React.Fragment key={index}>{element}</React.Fragment>)
                                }

                                <div className="d-flex mt-2 gap-20 align-center like"
                                     style={{justifyContent: "space-between"}}>
                                    <div className="d-flex mt-4 gap-20 align-center">
                                        <div
                                            className="back-button"
                                            onClick={() => {
                                                handleLikeClick(selectedResource?.id);
                                            }}
                                        >
                                            {likeStatus === true || selectedResource?.hasUserLiked === 1 ? (
                                                <AiFillHeart fill="red" fontSize={22} style={{cursor: "pointer"}}/>
                                            ) : (
                                                <AiOutlineHeart fontSize={22} style={{cursor: "pointer"}}/>
                                            )}
                                            Like
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <>
                            <div className="mt-4 project-by">
                                <div className="mt-4 author">A Resource By</div>
                                <hr className="mt-4" style={{borderColor: "#B7B7FE"}}/>
                                <div className="d-flex mt-4 gap-20 align-items-center">
                                <div>
                                        {selectedResource?.user?.full_profile_image !== null ? (
                                            <img crossOrigin="anonymous" className="author-image" src={selectedResource?.user?.full_profile_image}></img>
                                        ) : (
                                            <img crossOrigin="anonymous" className="author-image" src={avatarImage}></img>
                                        )}
                                    </div>

                                    <div>
                                        <div className="author-name">
                                            {selectedResource?.user?.first_name}&nbsp;{selectedResource?.user?.last_name}
                                        </div>
                                        <div className="author-school mt-2">{selectedResource?.user?.institution_poc?.name}</div>
                                        <div className="mt-2 author-description">{selectedResource?.user?.bio}</div>
                                    </div>
                                </div>
                                <hr className="mt-4" style={{ borderColor: "#B7B7FE" }} />
                            </div>
                        </>

                        {allComments?.length > 0 && (
                            <>
                                <div className="mt-4 d-flex gap-20">
                                    <img crossOrigin="anonymous" src={Chat2Icon}></img>
                                    <div className="comment-title"> {selectedResource?.comments}&nbsp; Comments</div>
                                </div>

                                <div className="project-container mt-4 project-comment-show" style={{ padding: "10px 10px" }}>
                                    <ResourceAllComments
                                        selectedResourceId={resourceId}
                                        allComments={allComments}
                                        getAllResourceComments={getAllResourceComments}
                                        getResourceDetails={getResourceDetails}
                                    />
                                </div>
                            </>
                        )}
                        <div className=" row mt-4 mb-3 py-0 project-container">
                            <AddComments selectedResourceId={resourceId} getAllResourceComments={getAllResourceComments} getResourceDetails={getResourceDetails} />
                        </div>
                    </div>
                    {showAddModal && (
                        <EditResource
                            onClose={closeAddModal}
                            selectedResourceId={resourceId}
                            resourceData={selectedResource}
                            getResourceDetails={getResourceDetails}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default ShowResourceDetails;