import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { forgotService } from "../../../../services/authService/forgotService";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setEmail } from "../../../../redux/authSlice";

const ForgotContentForm = () => {
    // states start
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // states end

    // initial values of all fields
    const initialValues = {
        email: "",
        otp: "",
    };

    // Validation schema
    const validationSchema = Yup.object({
        email: Yup.string()
            .test("noLeadingSpaces", "Invalid Input, Please avoid leading spaces at the beginning of the field", (value) => {
                return !value || value.trimStart() === value;
            })
            .email(t("COMMON.EMAIL.INVALID_FORMAT"))
            .required(t("COMMON.EMAIL.REQUIRED")),
    });

    // function for submit all values
    const onSubmit = (values) => {
        dispatch(setEmail(values?.email));
        setIsSubmitting(true);
        forgotService
            .addForgotData(values)
            .then((response) => {
                if (response?.data?.message === "OTP successfully sent on your registered email.") {
                    toast.success(response?.data?.message);
                    navigate("/verify-otp");
                }
            })
            .catch((ex) => {
                toast.error(ex?.response?.data?.message);
            });
    };

    // Use useFormik hook
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });

    return (
        <>
            {/*  JSX code for forgot form start */}
            <div className="login-form-content">
                <div className="login-form-title mb-4 mb-sm-5 ">
                    <h2>Dont worry</h2>
                    <h3>We’ll send you the reset OTP</h3>
                </div>

                <form onSubmit={formik.handleSubmit} className="login-form-field">
                    {/* email input field start */}
                    <div className="form-group mb-4">
                        <input
                            type="text"
                            id="email"
                            name="email"
                            className="form-control login-form-group"
                            placeholder={t("COMMON.EMAIL.ADDRESS")}
                            value={formik.values.email}
                            onChange={(e) => {
                                formik.handleChange(e);
                                setIsSubmitting(false);
                            }}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div className="text-danger" style={{ fontSize: "15px" }}>
                                {formik.errors.email}
                            </div>
                        ) : null}
                    </div>
                    {/* email input field end  */}

                    {/* verufy otp button start  */}
                    <button type="submit" className="btn login-button" disabled={!formik.dirty || isSubmitting}>
                        {t("COMMON.SEND.RESET_LINK")}
                    </button>
                    {/* verify otp button end  */}
                </form>
            </div>
            {/*  JSX code for forgot form end */}
        </>
    );
};

export default ForgotContentForm;
